import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Downshift from 'downshift';
import {
    FormSelectChoice, InputSelectOnKeyDown, FieldId, ExtendedFieldId,
} from '../../../lib/types/types';
import { InputSelectListComponent } from './InputSelectList/InputSelectListComponent';
import { InputSelectButtonComponent } from './InputSelectButton/InputSelectButtonComponent';
import { setRef } from '../../../lib/refs/refs';


interface InputSelectProps {
    onChange: any;
    options: FormSelectChoice[];
    fieldId: FieldId | ExtendedFieldId;
    className?: string;
    disabled?: boolean;
    inputId?: string;
    isErrored?: boolean;
    onKeyDown?: InputSelectOnKeyDown;
    placeholder?: string;
    suppressPlaceholder?: boolean;
    value?: FormSelectChoice;
    autoFocus?: boolean;
    ariaLabel?: string;
    isRequired?: boolean;
}

const InputSelect = ({
    className,
    inputId,
    disabled,
    fieldId,
    isErrored,
    options,
    onKeyDown,
    onChange,
    placeholder,
    // Most invokers want placeholder hidden by css, so it can be easily shown when
    // customizing the implementation. Birth date is an example of an exception.
    suppressPlaceholder = true,
    value,
    autoFocus,
    ariaLabel,
    isRequired,
}: InputSelectProps) => {
    const itemToString = item => (item ? item.label : '');

    const filterOptions = (options: FormSelectChoice[], inputValue: string): FormSelectChoice[] => {
        const filteredOptions: FormSelectChoice[] = (
            options.filter(option => !inputValue
                    || option.label.toLowerCase().includes(inputValue.toLowerCase())
                    || option.value.toString().toLowerCase().includes(inputValue.toLowerCase()))
        );
        return filteredOptions;
    };

    const handleOnBlur = (event: React.FormEvent<HTMLInputElement>, selectHighlightedItem: Function, selectedItem: FormSelectChoice) => {
        if ((!selectedItem) || (event.currentTarget.value !== selectedItem.label)) {
            selectHighlightedItem();
        }
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (typeof onKeyDown === 'function') {
            onKeyDown(event);
        }
    };

    return (
        <Downshift
            id={inputId}
            onChange={onChange}
            itemToString={itemToString}
            initialSelectedItem={value && value.value && value.label ? value : undefined}
            defaultHighlightedIndex={0}
        >
            {({
                clearSelection,
                getInputProps,
                getItemProps,
                getMenuProps,
                getToggleButtonProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
                selectedItem,
                selectHighlightedItem,
            }) => (
                <div className={`sid-input-select-list ${className}`}>
                    <input
                        {
                        ...getInputProps({
                            onFocus: () => openMenu(),
                            onBlur: event => handleOnBlur(event, selectHighlightedItem, selectedItem),
                            onKeyDown: event => handleOnKeyDown(event),
                        })
                        }
                        autoFocus={autoFocus}
                        id={inputId}
                        className={`sid-l-full-width sid-text-input ${suppressPlaceholder ? 'sid-hidden-placeholder' : ''} ${isErrored ? 'sid-text-input--error' : ''}`}
                        disabled={disabled}
                        placeholder={placeholder}
                        ref={input => setRef(fieldId, input)}
                        aria-required={isRequired}
                    />
                    <InputSelectButtonComponent
                        disabled={disabled}
                        getToggleButtonProps={getToggleButtonProps}
                        isOpen={isOpen}
                        clearSelection={selectedItem ? clearSelection : undefined}
                    />

                    {isOpen
                        ? (
                            <div>
                                {filterOptions(options, inputValue).length > 0
                                    ? (
                                        <InputSelectListComponent
                                            highlightedIndex={highlightedIndex}
                                            itemToString={itemToString}
                                            options={filterOptions(options, inputValue)}
                                            getItemProps={getItemProps}
                                            getMenuProps={getMenuProps}
                                        />
                                    )
                                    : (
                                        <div className="sid-input-select-list__menu" {...getMenuProps({ 'aria-label': ariaLabel })}>
                                            <div className="sid-input-select-list__message">
                                                <FormattedHTMLMessage
                                                    id="noOptions"
                                                    defaultMessage="No Options"
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                        : null}
                </div>
            )}
        </Downshift>
    );
};

InputSelect.defaultProps = {
    className: undefined,
    disabled: false,
    inputId: undefined,
    isErrored: false,
    onKeyDown: undefined,
    placeholder: '',
    value: undefined,
};

export const InputSelectComponent = InputSelect;

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormSelectChoice, InputSelectComponentProps } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';

export const Country = ({
    value,
    isErrored,
    options,
    onChange,
    onKeyDown,
    intl,
    placeholder = '',
    isRequired = false,
}: InputSelectComponentProps) => (
    <div className="sid-field sid-country">
        <div className="sid-l-space-top-md" />
        <label htmlFor="sid-country">
            <div className={`sid-field__label sid-country__label sid-l-space-btm-sm${isRequired ? ' sid-field__label--required' : ''}`}>
                <FormattedHTMLMessage
                    id="country"
                    defaultMessage="Country where you go to school"
                />
            </div>
        </label>
        <InputSelectComponent
            fieldId="country"
            inputId="sid-country"
            isErrored={isErrored}
            options={options}
            onChange={(country: FormSelectChoice) => onChange(country)}
            onKeyDown={onKeyDown}
            placeholder={placeholder || intl.formatMessage({ id: 'countryPlaceholder', defaultMessage: 'Country*' })}
            value={value ? {
                value: value.value,
                label: intl.formatMessage({ id: `countries.${value.value}`, defaultMessage: value.label || value.value }),
            } : undefined}
        />
        {
            isErrored ? (
                <div className="sid-field-error">
                    <FormattedHTMLMessage
                        id="errorId.invalidCountry"
                        defaultMessage="Invalid country"
                    />
                </div>
            ) : null
        }
    </div>
);

export const CountryComponent = injectIntl(Country);

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { VerificationService, ProgramTheme } from '../../lib/types/types';
import { getSafe } from '../../lib/utils/objects';
import { defaultMessages } from '../../lib/intl/messages/defaultMessages';

export const RewardsRemainingComponent = ({ verificationService }: {verificationService: VerificationService}) => {
    const offersRemaining: ProgramTheme['remainingRewardCodes'] = getSafe(() => verificationService.programTheme.remainingRewardCodes, null);

    if (typeof offersRemaining === 'number' && offersRemaining > 0) {
        return (<div className="sid-rewards-remaining">
            <div className="sid-l-space-top-md" />
            <FormattedHTMLMessage id="defaultMessages.onlyOffersLeft"
                defaultMessage={defaultMessages.onlyOffersLeft}
                values={{ offersRemaining }} />
            <div className="sid-l-space-top-sm" />
        </div>);
    }

    return null;
};

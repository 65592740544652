import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import {
    FormSelectChoice, FormFieldComponentProps, VerificationServiceProps, Country,
} from '../../../lib/types/types';
import { TypeaheadComponent } from '../../FormInputs/Typeahead/TypeaheadComponent';
import { getSafe } from '../../../lib/utils/objects';
import { getOptions } from '../../../options/options';

export const Company = ({
    onChange,
    value,
    isErrored,
    verificationService,
    intl,
    placeholder = '',
}: FormFieldComponentProps & VerificationServiceProps) => {
    const { programId } = verificationService;

    return (
        <div className="sid-field sid-company-id">
            <div className="sid-l-space-top-md" />
            <label htmlFor="sid-company">
                <div className={`sid-field__label sid-field__label--required sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                    <FormattedHTMLMessage
                        id="company"
                        defaultMessage="Company"
                    />
                </div>
            </label>

            <TypeaheadComponent
                className={isErrored ? 'sid-text-input--error' : ''}
                countryCode={getSafe<Country>(() => verificationService.viewModel.countryChoice.value)}
                minimumSearchValueLength={getOptions().minimumOrganizationSearchLength}
                onChange={(choice: FormSelectChoice) => onChange(choice)}
                placeholder={placeholder || intl.formatMessage({ id: 'companyPlaceholder', defaultMessage: 'Company*' })}
                programId={programId}
                value={value}
                inputHtmlId="sid-company"
                openOrgSearchEnabled={verificationService.programTheme.openOrgSearchEnabled}
                orgSearchUrl={verificationService.programTheme.config.orgSearchUrl}
            />
            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidOrganization"
                            defaultMessage="You must select an organization from the list"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export const CompanyComponent = injectIntl(Company);

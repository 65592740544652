import React from 'react';
import { InjectedIntl, injectIntl, FormattedHTMLMessage } from 'react-intl';

import { requestOrganizationConstants } from '../../constants';

import { validateFieldById } from '../../lib/validators/validators';
import { getDomainFromUrl } from '../../lib/utils/routing/Url';
import { fetchRequestOrganizations } from '../../lib/ServerApi/OrganizationApiClient';
import { RequestOrganizationContext } from '../../lib/RequestOrganizationService/RequestOrganizationStore';
import { submitAddSchoolRequest } from '../../lib/utils/requestOrgComponentHelpers/requestOrgComponentHelpers';
import {
    Organization,
    Locale,
    FormSelectChoice,
    RequestOrganizationService,
    Country,
} from '../../lib/types/types';
import {
    getAvailableLocaleChoices,
    getAvailableCountryChoices,
    getDefaultCountryChoice,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { FirstNameComponent } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent } from '../FormFields/LastName/LastNameComponent';
import { EmailComponent } from '../FormFields/Email/EmailComponent';
import { RequestOrganizationSearchResultComponent } from '../RequestOrganization/RequestOrganizationSearchResultComponent';
import { SearchFieldComponent } from '../RequestOrganization/SearchFieldComponent';
import { CountryComponent } from '../FormFields/Country/CountryComponent';
import { ChangeLocaleComponent } from '../FormFields/ChangeLocale/ChangeLocaleComponent';
import { LoadingSpinnerComponent } from '../LoadingSpinner/LoadingSpinnerComponent';
import { getLogoUrl, getOrgSearchCountryTags } from '../../lib/ProgramTheme/programThemeGetters';


interface RequestOrganizationSearchProps {
    intl: InjectedIntl;
}

export const filterOrgsFromParamsUrl = (dynamic, fixed) => {
    const eligibleOrgs = [];
    const ineligibleOrgs = [];

    const orgs = (dynamic as Organization[]).reduce((hashmap, organization) => {
        hashmap[organization.name] = true;
        eligibleOrgs.push(organization);
        return hashmap;
    }, {});

    (fixed as Organization[]).forEach((org: Organization) => {
        if (!orgs[org.name]) {
            ineligibleOrgs.push(org);
        }
    });
    return [eligibleOrgs, ineligibleOrgs];
};

const RequestOrganizationSearch = ({ intl }: RequestOrganizationSearchProps) => {
    const [requestOrganizationService, dispatch]: [RequestOrganizationService, Function] = React.useContext(RequestOrganizationContext);
    const viewModel = requestOrganizationService.viewModel;
    const programTheme = requestOrganizationService.programTheme;
    const localeChoices: FormSelectChoice<Locale, string>[] = getAvailableLocaleChoices(programTheme, intl);
    const defaultLocaleChoice: FormSelectChoice<Locale, string> = { value: 'en-US', label: 'English' };
    const countryChoices: FormSelectChoice<Country, string>[] = getAvailableCountryChoices(programTheme, intl);
    const defaultCountryChoice: FormSelectChoice<Country, string> = getDefaultCountryChoice(countryChoices);

    const updateRequestOrganizationViewModel = (updatedViewModel: any) => {
        dispatch({ type: 'UPDATE_VIEW_MODEL', payload: updatedViewModel });
    };

    const updateRequestOrganizationLocale = (locale: Locale) => {
        dispatch({ type: 'SET_LOCALE', payload: locale });
    };

    React.useEffect(() => {
        if (!viewModel.countryChoice) {
            updateRequestOrganizationViewModel({ countryChoice: defaultCountryChoice });
        }
    }, []);

    // country change handler
    React.useEffect(() => {
        if (!viewModel.countryChoice || viewModel.countryChoice.value === null) {
            updateRequestOrganizationViewModel({
                displayResults: false,
                completeRequest: false,
                firstNameError: null,
                lastNameError: null,
                emailError: null,
                eligibleOrgs: [],
                ineligibleOrgs: [],
            });
        } else {
            if (viewModel.searchByOrgName) {
                if (!!viewModel.orgName) {
                    searchByOrgName();
                }
            } else {
                if (!!viewModel.orgDomain) {
                    searchByOrgDomain();
                }
            }
        }
    },              [viewModel.countryChoice]);

    const searchOrganizations = async (dynamicParamsUrl: string, fixedParamsUrl: string) => {
        const eligibleOrgs = [];
        const ineligibleOrgs = [];

        updateRequestOrganizationViewModel({ isSearching: true });
        try {
            const [dynamicParamsResults, fixedParamsResults] = await Promise.all([
                fetchRequestOrganizations(dynamicParamsUrl),
                fetchRequestOrganizations(fixedParamsUrl),
            ]);

            const [eligibleOrgsFiltered, ineligibleOrgsFiltered] = filterOrgsFromParamsUrl(dynamicParamsResults, fixedParamsResults);
            eligibleOrgs.push(...eligibleOrgsFiltered);
            ineligibleOrgs.push(...ineligibleOrgsFiltered);
        } catch (e) {
            updateRequestOrganizationViewModel({ orgDomainError: 'invalidUrl', eligibleOrgs: [], ineligibleOrgs: [], isSearching: false });
            return;
        }

        updateRequestOrganizationViewModel({
            eligibleOrgs,
            ineligibleOrgs,
            completeRequest: false,
            firstNameError: null,
            lastNameError: null,
            emailError: null,
            displayResults: true,
            isSearching: false,
        });
    };

    const searchByOrgDomain = async () => {
        if (!canSearchByDomain()) {
            return;
        }
        const orgDomain = getDomainFromUrl(viewModel.orgDomain);
        const [dynamicParamsUrl, fixedParamsUrl] =  generateSearchOrgUrl('domain', orgDomain);
        searchOrganizations(dynamicParamsUrl, fixedParamsUrl);
    };

    const searchByOrgName = async () => {
        if (!canSearchByOrgName()) {
            return;
        }
        const [dynamicParamsUrl, fixedParamsUrl] =  generateSearchOrgUrl('name', viewModel.orgName);
        searchOrganizations(dynamicParamsUrl, fixedParamsUrl);
    };

    const generateSearchOrgUrl = (searchParamLabel, searchParamContent) => {
        const searchUrl = new URL(programTheme.config.orgSearchUrl);
        const country = viewModel.countryChoice.value;
        const searchTags = getOrgSearchCountryTags(programTheme, country);
        const { origin, pathname, search } = searchUrl;
        const searchParams = new URLSearchParams(search);

        searchParams.set(searchParamLabel, searchParamContent);
        searchParams.set('country', country);

        if (searchTags) {
            searchParams.set('tags', searchTags);
        }

        return [
            `${origin}${pathname}?${searchParams.toString()}`,
            `${origin}${pathname}?type=${requestOrganizationConstants.ORG_TYPES}&country=${country}&${searchParamLabel}=${searchParamContent}`,
        ];
    };

    const searchResultSmallEnough = () => {
        return (viewModel.eligibleOrgs.length + viewModel.ineligibleOrgs.length < requestOrganizationConstants.MAX_RESULT_SIZE);
    };

    const domainHasError = () => {
        const invalidUrlErrorId = 'invalidUrl';
        const requiredFieldErrorId = 'requiredField';

        return viewModel.orgDomain ? requestOrganizationConstants.URL_REGEX.test(viewModel.orgDomain) ? null : invalidUrlErrorId
                    : requiredFieldErrorId;
    };

    const orgNameHasError = () => viewModel.orgName ? null : 'requiredField';
    const countryHasError = () => viewModel.countryChoice && viewModel.countryChoice.value ? null : 'requiredField';


    const canSubmitForm = () => {
        const orgCountryError = countryHasError();
        const orgDomainError = domainHasError();
        const orgNameError = orgNameHasError();
        const firstNameError = validateFieldById('firstName', viewModel.firstName);
        const lastNameError = validateFieldById('lastName', viewModel.lastName);
        const emailError = validateFieldById('email', viewModel.email);

        updateRequestOrganizationViewModel({
            orgCountryError,
            orgDomainError,
            orgNameError,
            firstNameError,
            lastNameError,
            emailError,
        });

        return !(orgCountryError || orgDomainError || orgNameError || firstNameError || lastNameError || emailError);
    };

    const canSearchByDomain = () => {
        const orgCountryError = countryHasError();
        const orgDomainError = domainHasError();
        updateRequestOrganizationViewModel({ orgCountryError, orgDomainError });
        return !(orgCountryError || orgDomainError);
    };

    const canSearchByOrgName = () => {
        const orgCountryError = countryHasError();
        const orgNameError = orgNameHasError();
        updateRequestOrganizationViewModel({ orgCountryError, orgNameError });
        return !(orgCountryError || orgNameError);
    };

    const displayOrgNameSearch = () => !viewModel.searchByOrgName && viewModel.displayResults;
    const displayCompleteRequest = () => viewModel.searchByOrgName && viewModel.displayResults && searchResultSmallEnough();

    const logoUrl = programTheme ? getLogoUrl(programTheme) : null;

    return (
        <div className="sid-l-container--extra-wide sid-l-space-btm-lg sid-l-container">
            <div className="sid-logo sid-l-horz-center sid-l-space-top-lg">
                <img
                    className="sid-logo__img"
                    alt="Logo"
                    src={ logoUrl || requestOrganizationConstants.SHEERID_LOGO_URL }
                />
            </div>
            <div className="sid-logo sid-l-space-top-md">
                <img
                    className="sid-logo__icon"
                    alt="School House"
                    src={ requestOrganizationConstants.SCHOOL_HOUSE_URL }
                />
            </div>

            {
                localeChoices.length > 1 ?
                    <ChangeLocaleComponent
                        options={localeChoices}
                        value={viewModel.localeChoice || defaultLocaleChoice}
                        isErrored={false}
                        onChange={(localeChoice: FormSelectChoice<Locale, string>) => {
                            updateRequestOrganizationViewModel({ localeChoice });
                            updateRequestOrganizationLocale(localeChoice.value);
                        }}
                    />
                : null
            }

            <div className="sid-title">
                <FormattedHTMLMessage
                    id="title"
                    defaultMessage="Request to Add School"
                />
            </div>

            <div className="sid-soft-font-color">
                <FormattedHTMLMessage
                    id="description"
                    defaultMessage="Please fill out the form below. Schools submitted are not guaranteed to be added, but all requests are researched and considered by SheerID."
                />
            </div>

            <CountryComponent
                value={ viewModel.countryChoice || defaultCountryChoice}
                isErrored={ viewModel.orgCountryError ? true : false }
                onChange={(countryChoice: FormSelectChoice<Country, string>) => {
                    updateRequestOrganizationViewModel({ countryChoice, orgCountryError: null });
                }}
                options={countryChoices}
                placeholder={ intl.formatHTMLMessage({ id: 'fields.countryPlaceholder', defaultMessage: 'Select Country' }) }
            />

            <div className="sid-l-space-top-md">
                <FormattedHTMLMessage
                    id="noCountry"
                    defaultMessage="Don't see your school's country? You are not eligible for this offer."
                />
            </div>

            <div className={ `${viewModel.searchByOrgName ? 'sid-field-pair' : ''}` }>
                <SearchFieldComponent
                    name="org-domain"
                    label={ intl.formatHTMLMessage({ id: 'fields.domainLabel', defaultMessage: 'School website' }) }
                    placeholder={ intl.formatHTMLMessage({ id: 'fields.domainPlaceholder', defaultMessage: 'www.myschool.edu' }) }
                    value={ viewModel.orgDomain }
                    isErrored={ viewModel.orgDomainError }
                    onChange={ (orgDomain: string) => {
                        updateRequestOrganizationViewModel({ orgDomain });
                    } }
                    onKeyDown={ () => {
                        viewModel.orgDomainError && !domainHasError() ?
                            updateRequestOrganizationViewModel({ orgDomainError: null })
                        : null;
                    }}
                    search={ searchByOrgDomain }
                />
                {
                    viewModel.searchByOrgName ?
                        <SearchFieldComponent
                            name="org-name"
                            label={ intl.formatHTMLMessage({ id: 'fields.orgNameLabel', defaultMessage: 'School name' }) }
                            placeholder={ intl.formatHTMLMessage({ id: 'fields.orgNamePlaceholder', defaultMessage: 'My school' }) }
                            value={ viewModel.orgName }
                            isErrored={ viewModel.orgNameError }
                            onChange={ (orgName: string) => {
                                updateRequestOrganizationViewModel({ orgName });
                            } }
                            onKeyDown={ () => {
                                viewModel.orgNameError && !orgNameHasError() ?
                                    updateRequestOrganizationViewModel({ orgNameError: null })
                                    : null;
                            }}
                            search={ searchByOrgName }
                        />
                    : null
                }
            </div>
            {
                viewModel.displayResults ?
                    <RequestOrganizationSearchResultComponent
                        eligibleOrgs={ viewModel.eligibleOrgs }
                        ineligibleOrgs={ viewModel.ineligibleOrgs }
                    />
                : null
            }
            {
                displayOrgNameSearch() ?
                    <div className="sid-l-space-top-md">
                        <div
                            className="sid-h-link-like"
                            onClick={ () => {
                                if (canSearchByDomain()) {
                                    updateRequestOrganizationViewModel({
                                        searchByOrgName: true,
                                        displayResults: false,
                                        eligibleOrgs: [],
                                        ineligibleOrgs: [],
                                    });
                                }
                            } }
                        >
                            <FormattedHTMLMessage
                                id="searchByOrgName"
                                defaultMessage="Don't see your school? Search by name."
                            />
                        </div>
                    </div>
                : null
            }
            {
                displayCompleteRequest() ?
                    <div className="sid-l-space-top-md">
                        <div
                            className="sid-h-link-like"
                            onClick={ () => {
                                if (canSearchByDomain() && canSearchByOrgName()) {
                                    updateRequestOrganizationViewModel({ completeRequest: true });
                                }
                            } }
                        >
                            <FormattedHTMLMessage
                                id="completeRequest"
                                defaultMessage="Don't see your school in the list of eligible or ineligible schools? Complete your request"
                            />
                        </div>
                    </div>
                : null
            }
            {
                viewModel.completeRequest ?
                    <div className="sid-complete-request">
                        <div className="sid-personal-info">
                            <div className="sid-field-pair">
                                <FirstNameComponent
                                    value={ viewModel.firstName }
                                    isErrored={ viewModel.firstNameError ? true : false }
                                    onChange={(newValue) => {
                                        updateRequestOrganizationViewModel({ firstName: newValue });
                                    }}
                                />
                                <LastNameComponent
                                    value={ viewModel.lastName }
                                    isErrored={ viewModel.lastNameError ? true : false}
                                    onChange={(newValue) => {
                                        updateRequestOrganizationViewModel({ lastName: newValue });
                                    }}
                                />
                            </div>
                            <EmailComponent
                                value={ viewModel.email }
                                isErrored={ viewModel.emailError ? true : false}
                                onChange={(newValue) => {
                                    updateRequestOrganizationViewModel({ email: newValue });
                                }}
                                explanation=" "
                            />
                        </div>
                        <div className="sid-l-space-top-lg">
                            <button
                                type="submit"
                                className="sid-btn sid-btn--dark"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    submitAddSchoolRequest(canSubmitForm, requestOrganizationService, dispatch);
                                } }
                            >
                                <FormattedHTMLMessage
                                    id="submit"
                                    defaultMessage="Submit"
                                />
                            </button>
                        </div>
                    </div>
                : null
            }
            {
                viewModel.isSearching ?
                <div id="sid-spinner-container" className="sid-l-absolute-center"><LoadingSpinnerComponent /></div>
                : null
            }
        </div>
    );
};

export const RequestOrganizationSearchComponent = injectIntl(RequestOrganizationSearch);

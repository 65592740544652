/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from '../../types/types';

export const ageMessages: SegmentSpecificMessages = {
    emailExplanation: 'Needed to send you your unique code',
    step: {
        personalInfo: {
            title: 'Unlock Your Special Offer',
            subtitle: 'Verify your current age.',
            howDoesVerifyingWorkDetails: `
            To be eligible for this offer, you must be of a certain age.
            <br><br>
            {companyName} uses SheerID, a trusted partner, to verify your age. SheerID only collects information that assists in
            verifying your eligibility. Additional information or documentation may be requested.`,
            tryAgain: {
                title: 'Check Your Name & Birthdate',
                subtitle: 'Your full name and birthdate must match what is on your government-issued documents.',
            },
        },
        docUpload: {
            howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying age.
            <br><br>
            Documents are used only for verifying your current age and will be permanently deleted after review is finalized. They will not be shared with {companyName}`,
            uploadInstructions: 'Upload a government-issued document that shows',
            title: 'We need your license or passport',
            subtitle: 'Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.',
        },
        success: {
            title: "You've been verified",
            subtitle: 'Here is your personal coupon code. It is unique to you and can only be used once.',
            redirectButtonText: 'Use code now',
        },
    },
};

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { VerificationService } from '../../lib/types/types';
import { getFaqLink } from '../../lib/ProgramTheme/programThemeGetters';

interface Props {
    verificationService: VerificationService;
}

const FaqLinkComponent: React.SFC<Props> = ({ verificationService }) => {
    const { segment } = verificationService.verificationResponse;
    const { programId, programTheme } = verificationService;

    if (segment) {
        const faqHref = getFaqLink(programTheme, segment, programId);

        return (
            <a className="sid-faq sid-link" href={faqHref} target="_blank">
                <FormattedHTMLMessage id="sheeridFaqs" defaultMessage="SheerID FAQs" />
            </a>
        );
    }
    return null;
};

export { FaqLinkComponent };

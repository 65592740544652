import React from 'react';
import { getEmptyRequestOrganizationViewModel } from './RequestOrganizationGetters';
import { RequestOrganizationService } from '../types/types';
import { requestOrganizationReducer } from './RequestOrganizationReducer';
import { deepClone } from '../utils/objects';


const initialState: RequestOrganizationService = Object.freeze({
    viewModel: getEmptyRequestOrganizationViewModel(),
    programTheme: undefined,
    programId: undefined,
    submitted: false,
    error: undefined,
    locale: undefined,
    messages: undefined,
    isLoading: 1,
    isInitialized: false,
});


const getInitialState = () => deepClone(initialState);


export const RequestOrganizationStore = ({ children }) => {
    const [state, dispatch]: [RequestOrganizationService, any] = React.useReducer(requestOrganizationReducer, getInitialState());

    return (
        <RequestOrganizationContext.Provider value={[state, dispatch]}>
            {children}
        </RequestOrganizationContext.Provider>
    );
};

export const RequestOrganizationContext = React.createContext<[RequestOrganizationService, Function]>([getInitialState(), () => {}]);

import { Options, RestApiOptions, Locale, CookieOptions } from '../lib/types/types';
import { logger } from '../lib/utils/logger/logger';
import { ensureTrailingSlash } from '../lib/utils/routing/Url';
import { assertValidMockStepName, assertValidLocale } from '../lib/types/assertions';
import { DEFAULT_MINIMUM_ORG_SEARCH_VALUE_LENGTH, HTTP_REQUEST_TIMEOUT } from '../constants';
import { registerAdditionalLocales } from '../lib/types/runtimeTypes';
import { deepMerge } from '../lib/utils/objects';

const restOptions: RestApiOptions = {
    serviceUrl: 'https://services.sheerid.com/',
    resources: {
        verification: 'rest/v2/verification/',
        program: {
            base: 'rest/v2/program/',
            theme: 'theme',
            organization: 'organization',
        },
        conversion: {
            base: 'rest/v2/conversion/',
        },
    },
};

const cookieOptions: CookieOptions = {
    enabled: true,
    secure: true, // default: true - use with https://
    expires: 7, // default: 7 days
};

const defaultOptions: Options = {
    restApi: restOptions,
    mockStep: undefined,
    mockSubSegment: undefined,
    mockErrorId: undefined,
    doFetchTheme: true,
    logLevel: 'error',
    locale: null,
    messages: {}, /** @deprecated */
    messagesWithLocale: {},
    urlStudentFaq: 'https://verify.sheerid.com/student-faq/', // defaults
    urlSeniorFaq: 'https://verify.sheerid.com/us-senior-faq/',
    urlAgeFaq: 'https://verify.sheerid.com/us-age-faq/',
    urlMilitaryFaq: 'https://verify.sheerid.com/military-faq/',
    urlTeacherFaq: 'https://verify.sheerid.com/us-teacher-faq/',
    urlMemberFaq: 'https://verify.sheerid.com/membership-faq/',
    urlFirstResponderFaq: 'https://verify.sheerid.com/first-responder-faq/',
    urlMedicalFaq: 'https://verify.sheerid.com/us-medical-faq/',
    urlEmploymentFaq: 'https://verify.sheerid.com/employment-faq/',
    urlIdentityFaq: 'https://verify.sheerid.com/identity-faq/',
    urlAddSchoolFaq: 'https://verify.sheerid.com/add-school-request-faq/',
    urlAddSchoolForm: 'https://offers.sheerid.com/sheerid/add-school-request/',
    privacyPolicyUrl: undefined,
    cookies: cookieOptions,
    useFingerprinting: false,
    verificationId: undefined,
    minimumOrganizationSearchLength: DEFAULT_MINIMUM_ORG_SEARCH_VALUE_LENGTH,
    httpRequestTimeout: HTTP_REQUEST_TIMEOUT,
};

let options = Object.assign({}, defaultOptions);

export const getOptions = (): Options => {
    return options;
};

export const resetOptions = () => {
    options = Object.assign({}, defaultOptions);
};

export const setOptions = (newOptions: any) => {
    if (newOptions.hasOwnProperty('logLevel')) {
        try {
            logger.setLogLevel(newOptions.logLevel);
        } catch (e) {
            throw e;
        }
        options.logLevel = newOptions.logLevel; // keep options in sync with logger
    }

    if (newOptions.hasOwnProperty('restApi')) {
        const restApiOptions = newOptions.restApi;
        if (restApiOptions.hasOwnProperty('serviceUrl')) {
            const newUrl = ensureTrailingSlash(newOptions.restApi.serviceUrl);
            options.restApi!.serviceUrl = newUrl;
            logger.info(`option "serviceUrl" set to ${options.restApi!.serviceUrl}`);
        }
    }

    if (newOptions.hasOwnProperty('mockStep')) {
        assertValidMockStepName(newOptions.mockStep);
        options.mockStep = newOptions.mockStep;
        logger.info(`option "mockStep" set to ${options.mockStep}`);
    }

    if (newOptions.hasOwnProperty('mockSubSegment')) {
        options.mockSubSegment = newOptions.mockSubSegment;
        logger.info(`option "mockSubSegment" set to ${options.mockSubSegment}`);
    }

    if (newOptions.hasOwnProperty('mockErrorId')) {
        options.mockErrorId = newOptions.mockErrorId;
        logger.info(`option "mockErrorId" set to ${options.mockErrorId}`);
    }

    if (newOptions.hasOwnProperty('messages')) {
        options.messagesWithLocale = Object.assign({}, options.messagesWithLocale, { 'en-US': newOptions.messages });
        logger.warn(
            'option "messages" has been deprecated and replaced with "messagesWithLocale". Messages have been set for locale "en-US" using: ',
            options.messagesWithLocale,
            '\nThese messages will override existing messages of the same key for "en-US" locale.',
        );
    }

    if (newOptions.hasOwnProperty('messagesWithLocale')) {
        options.messagesWithLocale = deepMerge({}, options.messagesWithLocale, newOptions.messagesWithLocale);
        if (newOptions && newOptions.messagesWithLocale) {
            registerAdditionalLocales(Object.keys(newOptions.messagesWithLocale) as Locale[]);
        }
        logger.info('option "messagesWithLocale" set to', options.messagesWithLocale);
    }

    if (newOptions.hasOwnProperty('locale')) {
        assertValidLocale(newOptions.locale);
        options.locale = newOptions.locale;
        logger.info(`option "locale" set to ${options.locale}`);
    }

    if (newOptions.hasOwnProperty('urlStudentFaq')) {
        // assertValidUrl(newOptions.urlStudentFaq);
        options.urlStudentFaq = newOptions.urlStudentFaq;
        logger.info(`option "urlStudentFaq" set to ${options.urlStudentFaq}`);
    }

    if (newOptions.hasOwnProperty('urlSeniorFaq')) {
        // assertValidUrl(newOptions.urlSeniorFaq);
        options.urlSeniorFaq = newOptions.urlSeniorFaq;
        logger.info(`option "urlSeniorFaq" set to ${options.urlSeniorFaq}`);
    }

    if (newOptions.hasOwnProperty('urlMilitaryFaq')) {
        // assertValidUrl(newOptions.urlMilitaryFaq);
        options.urlMilitaryFaq = newOptions.urlMilitaryFaq;
        logger.info(`option "urlMilitaryFaq" set to ${options.urlMilitaryFaq}`);
    }

    if (newOptions.hasOwnProperty('urlTeacherFaq')) {
        // assertValidUrl(newOptions.urlTeacherFaq);
        options.urlTeacherFaq = newOptions.urlTeacherFaq;
        logger.info(`option "urlTeacherFaq" set to ${options.urlTeacherFaq}`);
    }

    if (newOptions.hasOwnProperty('urlMemberFaq')) {
        // assertValidUrl(newOptions.urlMemberFaq);
        options.urlMemberFaq = newOptions.urlMemberFaq;
        logger.info(`option "urlMemberFaq" set to ${options.urlMemberFaq}`);
    }

    if (newOptions.hasOwnProperty('urlMedicalFaq')) {
        options.urlMedicalFaq = newOptions.urlMedicalFaq;
        logger.info(`option "urlMedicalFaq" set to ${options.urlMedicalFaq}`);
    }

    if (newOptions.hasOwnProperty('urlEmploymentFaq')) {
        options.urlEmploymentFaq = newOptions.urlEmploymentFaq;
        logger.info(`option "urlEmploymentFaq" set to ${options.urlEmploymentFaq}`);
    }

    if (newOptions.hasOwnProperty('urlAddSchoolFaq')) {
        options.urlAddSchoolFaq = newOptions.urlAddSchoolFaq;
        logger.info(`option "urlAddSchoolFaq" set to ${options.urlAddSchoolFaq}`);
    }

    if (newOptions.hasOwnProperty('urlAddSchoolForm')) {
        options.urlAddSchoolForm = newOptions.urlAddSchoolForm;
        logger.info(`option "urlAddSchoolForm" set to ${options.urlAddSchoolForm}`);
    }

    if (newOptions.hasOwnProperty('doFetchTheme')) {
        options.doFetchTheme = newOptions.doFetchTheme;
        logger.info(`option "doFetchTheme" set to ${options.doFetchTheme}`);
    }

    if (newOptions.hasOwnProperty('cookies')) {
        options.cookies = Object.assign({}, options.cookies, newOptions.cookies);
        logger.info('option "cookies" set to', options.cookies);
    }

    if (newOptions.hasOwnProperty('useFingerprinting')) {
        options.useFingerprinting = newOptions.useFingerprinting;
        logger.info('option "useFingerprinting" set to', options.useFingerprinting);
    }

    if (newOptions.hasOwnProperty('marketConsent')) {
        options.marketConsent = newOptions.marketConsent;
        logger.info('option "market consent" set to', options.marketConsent);
    }

    if (newOptions.hasOwnProperty('verificationId')) {
        options.verificationId = newOptions.verificationId;
        logger.info('option "verificationId" set to', options.verificationId);
    }

    if (newOptions.hasOwnProperty('minimumOrganizationSearchLength')) {
        options.minimumOrganizationSearchLength = newOptions.minimumOrganizationSearchLength;
        logger.info('option "minimumOrganizationSearchLength" set to', options.minimumOrganizationSearchLength);
    }

    if (newOptions.hasOwnProperty('customCss')) {
        options.customCss = newOptions.customCss;
        logger.info('option "customCss" set to', options.customCss);
    }

    if (newOptions.hasOwnProperty('logoUrl')) {
        options.logoUrl = newOptions.logoUrl;
        logger.info('option "logoUrl" set to', options.logoUrl);
    }

    if (newOptions.hasOwnProperty('httpRequestTimeout')) {
        options.httpRequestTimeout = newOptions.httpRequestTimeout;
        logger.info('option "httpRequestTimeout" set to', options.httpRequestTimeout);
    }

    if (newOptions.hasOwnProperty('privacyPolicyUrl')) {
        options.privacyPolicyUrl = newOptions.privacyPolicyUrl;
        logger.info('option "privacyPolicyUrl" set to', options.privacyPolicyUrl);
    }
};

/**
 * TODO - preamble
 */
import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';

import { VerificationStepsEnum, MedicalProfessionalStatusDefaultMessagesEnum } from '../../lib/types/runtimeTypes';
import { isFormFilled } from '../../lib/validators/validators';
import { hasFailedInstantMatch } from '../../lib/VerificationService/VerificationServiceHelpers';
import { setRef } from '../../lib/refs/refs';
import { setFocus } from '../../lib/utils/browser/inputHelpers';
import {
    VerificationService,
    FieldValidationErrors,
    ExtendedFieldValidationErrors,
    FormSelectChoice,
    Organization,
    InputSelectOnKeyDown,
    MedicalProfessionalPersonalInfoViewModel,
    MedicalProfessionalPersonalInfoResponse,
} from '../../lib/types/types';
import {
    handleEmailOnKeyDown,
    submitForm,
    updateFieldValidationErrorsByFieldId,
    updateViewModelOrganization,
    produceDraftViewModel,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from '../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { PhoneNumberComponent as PhoneNumber } from '../FormFields/PhoneNumber/PhoneNumberComponent';
import { FirstNameComponent as FirstName } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent as LastName } from '../FormFields/LastName/LastNameComponent';
import { EmailComponent as Email } from '../FormFields/Email/EmailComponent';
import { BirthDateComponent as BirthDate } from '../FormFields/BirthDate/BirthDateComponent';
import { MarketConsentWrapperComponent as MarketConsentWrapper } from '../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent';
import { PostalCodeComponent as PostalCode } from '../FormFields/PostalCode/PostalCodeComponent';
import { MedicalStatusComponent as MedicalStatus } from '../FormFields/MedicalStatus/MedicalStatusComponent';
import { MedicalProfessionalOrganizationComponent as MedicalProfessionalOrganization } from '../FormFields/MedicalProfessionalOrganization/MedicalProfessionalOrganizationComponent';
import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { CountryComponentWrapper } from '../FormFields/Country/CountryComponentWrapper';
import { RewardsRemainingComponent } from '../RewardsRemaining/RewardsRemainingComponent';

interface StepMedicalProfessionalPersonalInfoComponentProps {
    verificationService: VerificationService;
    intl: any;
}

export const StepMedicalProfessionalPersonalInfo = ({ intl, verificationService }: StepMedicalProfessionalPersonalInfoComponentProps) => {
    const viewModel = verificationService.viewModel as MedicalProfessionalPersonalInfoViewModel;
    const fieldValidationErrors: FieldValidationErrors & ExtendedFieldValidationErrors = verificationService.fieldValidationErrors;
    const verificationResponse = verificationService.verificationResponse as MedicalProfessionalPersonalInfoResponse;
    const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

    const updateMedicalProfessionalViewModel = (key: keyof MedicalProfessionalPersonalInfoViewModel, value: any) => {
        const nextState = produceDraftViewModel(viewModel, key, value);
        verificationService.updateViewModel(nextState);
    };

    const getAvailableMedicalStatuses = () => {
        const availableStatusesResponse = verificationResponse.availableStatuses;
        const availableStatuses: FormSelectChoice[] = [];

        for (const status of availableStatusesResponse) {
            availableStatuses.push({
                value: status,
                label: intl.formatMessage({ id: status, defaultMessage: MedicalProfessionalStatusDefaultMessagesEnum[status] }),
            });
        }
        return availableStatuses;
    };

    const handleMedicalStatusOnKeyDown: InputSelectOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            setFocus('organization');
        }
    };

    return (
        <div id="sid-step-active-medical-professional-personal-info" className="sid-l-container">
            { failedInstantMatch ?
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.title" defaultMessage="Check Your Name, Email & Organization" />
                    </div>
                    <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.subtitle"
                            defaultMessage="Your full name, organization name, and email must match what is on record with your agency." />
                    </div>
                </div>
            :
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.title" defaultMessage="Unlock this Medical Professional-Only Offer" />
                    </div>
                    <div className="sid-header__subtitle sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.subtitle"
                            defaultMessage="Verify you're a licensed medical professional." />
                    </div>
                    <div className="sid-header__how-verifying-works sid-l-horz-center">
                        <HowDoesVerifyingWork verificationService={verificationService} />
                        <RewardsRemainingComponent verificationService={verificationService} />
                    </div>
                </div>
            }

            <CountryComponentWrapper
                verificationService={verificationService}
                viewModel={viewModel}
            />

            <MedicalStatus
                value={{
                    value: viewModel.status,
                    label: MedicalProfessionalStatusDefaultMessagesEnum[viewModel.status],
                }}
                isErrored={!!fieldValidationErrors.status}
                options={ getAvailableMedicalStatuses() }
                onChange={
                    (status: FormSelectChoice) => {
                        updateMedicalProfessionalViewModel('status', status ? (status.value as string) : '');
                        updateFieldValidationErrorsByFieldId('status', status ? (status.value as string) : '', verificationService);
                        if (status) {
                            setFocus('organization');
                        }
                    }
                }
                onKeyDown={event => handleMedicalStatusOnKeyDown(event)}
            />

            <MedicalProfessionalOrganization
                isErrored={!!fieldValidationErrors.organization}
                onChange={
                    (choice: Organization) => {
                        updateViewModelOrganization(choice, verificationService);
                        updateFieldValidationErrorsByFieldId('organization', choice, verificationService);
                    }
                }
                value={(verificationService.viewModel as MedicalProfessionalPersonalInfoViewModel).organization}
                verificationService={verificationService}
            />

            <div className="sid-names">
                <FirstName

                    value={viewModel.firstName}
                    isErrored={!!fieldValidationErrors.firstName}
                    onChange={
                        (newValue) => {
                            updateMedicalProfessionalViewModel('firstName', newValue);
                            updateFieldValidationErrorsByFieldId('firstName', newValue, verificationService);
                        }
                    }
                />

                <LastName

                    value={viewModel.lastName}
                    isErrored={!!fieldValidationErrors.lastName}
                    onChange={
                        (newValue) => {
                            updateMedicalProfessionalViewModel('lastName', newValue);
                            updateFieldValidationErrorsByFieldId('lastName', newValue, verificationService);
                        }
                    }
                />
            </div>

            <BirthDate
                isErrored={!!fieldValidationErrors.birthDate}
                errorId={fieldValidationErrors.birthDate}
                onChange={(newValue) => {
                    updateMedicalProfessionalViewModel('birthDate', newValue);
                    updateFieldValidationErrorsByFieldId('birthDate', newValue, verificationService);
                }}
                value={viewModel.birthDate}
            />

            <PostalCode
                isErrored={!!fieldValidationErrors.postalCode}
                onChange={(newValue) => {
                    updateMedicalProfessionalViewModel('postalCode', newValue);
                    updateFieldValidationErrorsByFieldId('postalCode', newValue, verificationService);
                }}
                value={viewModel.postalCode}
                explanation={<FormattedHTMLMessage
                    id="postalCodeExplanation"
                    defaultMessage="Use the location on file with your licensing body"
                />}
            />

            <Email
                value={viewModel.email}
                isErrored={!!fieldValidationErrors.email}
                explanation={
                    <FormattedHTMLMessage
                        id="emailExplanation"
                        defaultMessage="Needed to send you your unique code"
                    />
                }
                onChange={
                    (newValue) => {
                        updateMedicalProfessionalViewModel('email', newValue);
                        updateFieldValidationErrorsByFieldId('email', newValue, verificationService);
                    }
                }
                onKeyDown={event => handleEmailOnKeyDown(event)}
            />
            {
                verificationService.programTheme.isSmsNotifierConfigured || verificationService.programTheme.smsLoopEnabled ?
                    <PhoneNumber
                        isRequired={!!verificationService.programTheme.smsLoopEnabled}
                        value={viewModel.phoneNumber}
                        isErrored={!!fieldValidationErrors.phoneNumber}
                        onChange={
                            (newValue) => {
                                updateMedicalProfessionalViewModel('phoneNumber', newValue);
                                updateFieldValidationErrorsByFieldId('phoneNumber', newValue, verificationService);
                            }
                        }
                    />
                    : null
            }
            <MarketConsentWrapper
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.marketConsentValue}
                onChange={(newValue) => {
                    updateMedicalProfessionalViewModel('metadata', { ...verificationService.viewModel.metadata, marketConsentValue: newValue });
                    updateFieldValidationErrorsByFieldId('marketConsentValue', newValue, verificationService);
                }}
                viewModel={viewModel}
            />

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-btn-collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectMedicalProfessionalPersonalInfo)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${!isFormFilled(viewModel, verificationService.formValidationOptions) ? 'sid-btn--disabled-like' : ''}`}
                        aria-labelledby="verify-status-text"
                        ref={button => setRef('submitButton', button)}
                    >
                        <span id="verify-status-text">
                            { failedInstantMatch ?
                                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
                            :
                                <FormattedHTMLMessage id="verifyMyMedicalProfessionalStatus" defaultMessage="Verify My Medical Professional Status" />
                            }
                        </span>
                    </button>
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepMedicalProfessionalPersonalInfoComponent = injectIntl(StepMedicalProfessionalPersonalInfo);

import React, { useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Segment, VerificationService, VerificationStep } from '../../lib/types/types';
import { FaqLinkComponent } from '../FaqLink/FaqLinkComponent';
import { assertValidSegmentName } from '../../lib/types/assertions';
import {  VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { howDoesVerifyingWorkMessages } from './HowDoesVerifyingWorkMessages';
import { getSafe } from '../../lib/utils/objects';
import { PrivacyPolicyLinkComponent } from '../PrivacyPolicyLink/PrivacyPolicyLinkComponent';

interface HowDoesVerifyingWorkProps {
    verificationService: VerificationService;
    initialVisibility?: boolean;
}

const getDefaultDetailsMessage = (step: VerificationStep, segment: Segment):string => {
    const messageType = step === VerificationStepsEnum.docUpload ? 'docUpload' : 'default';
    const defaultMessages = howDoesVerifyingWorkMessages();
    assertValidSegmentName(segment);
    const message = getSafe(() => defaultMessages[segment][messageType], '-');
    return message;
};

const HowDoesVerifyingWorkComponent : React.FC<HowDoesVerifyingWorkProps> = ({ verificationService, initialVisibility = false }) => {
    const [tooltipVisibility, setTooltipVisibility] = useState(initialVisibility);
    const segment = verificationService.verificationResponse.segment;
    const step = verificationService.verificationResponse.currentStep;
    const defaultMessage = `${getDefaultDetailsMessage(step, segment)}`;
    const showTooltip = () => setTooltipVisibility(true);
    const hideTooltip = () => setTooltipVisibility(false);
    const handleBlur = () => setTimeout(() => setTooltipVisibility(false), 0);

    return (
            <div
                tabIndex={0}
                className="sid-how-verify-works"
                aria-describedby="how-does-verifying-work"
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                onFocus={showTooltip}
                onBlur={handleBlur}
            >
                <span className="sid-link">
                    <FormattedHTMLMessage id="howDoesVerifyingWork"
                        defaultMessage="How does verifying work?" />
                </span>
                {
                    tooltipVisibility ?
                        <div className="sid-how-verify-works__container">
                            <div className="sid-how-verify-works__caret" />
                            <div className="sid-how-verify-works__tooltip" role="tooltip" id="how-does-verifying-work">
                                <FormattedMessage id="companyName" defaultMessage="{Company}">
                                    {companyName => (
                                        <FormattedHTMLMessage
                                            id={`step.${step === VerificationStepsEnum.docUpload ? 'docUpload' : 'personalInfo'}.howDoesVerifyingWorkDetails`}
                                            defaultMessage={defaultMessage}
                                            values={{ companyName }}
                                        />
                                    )}
                                </FormattedMessage>
                                &nbsp;
                                <FaqLinkComponent verificationService={verificationService} />
                                &nbsp;
                                <span>+</span>
                                &nbsp;
                                <PrivacyPolicyLinkComponent verificationService={verificationService} includeCompanyName={false} />
                            </div>
                        </div>
                    : null
                }
        </div>
    );
};

export { HowDoesVerifyingWorkComponent };

import { SSN_STRING_LENGTH } from '../../../constants';
import '../../../polyfills/strings';

export const displaySSN = (value: string) => {
    let stringValue;
    try {
        stringValue = value.toString();
        if (!stringValue) throw new Error();
    } catch (e) {
        return '';
    }
    return stringValue
        .replace(/[^\d]/g, '') // replce non-digit chars
        .insertAt(3, '-') // for display
        .insertAt(6, '-')
        .replace('--', '') // just in case
        .replace(/-$/, ''); // don't add trailing hyphen, breaks backspace
};

// The viewModel and API don't include hyphens. Also enforces max length, prior to setting viewModel, avoiding off-by-one error.
export const unDisplaySSN = (value: string) => value.replace(/-/g, '').slice(0, SSN_STRING_LENGTH);

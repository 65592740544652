import { createUpdateViewModel, doViewModelReset } from './VerificationServiceActions';
import { getStore } from './VerificationServiceStore';
import { ViewModel, ReduxState } from '../types/types';
import { addHook } from '../hooks/hooks';

import { logger } from '../utils/logger/logger';
import { deepClone } from '../utils/objects';

const store = getStore();

/**
 * @description Set the viewModel for the form or reset it by passing an empty object
 * @todo allow sparse viewModel to be provided
 */
export const setViewModel = (newViewModel: ViewModel | {}) => {
    const state: ReduxState = store.getState();
    const doSet = () => {
        const updatedViewModel = deepClone(newViewModel);
        logger.info('Setting viewModel', updatedViewModel);
        createUpdateViewModel(store.dispatch)(updatedViewModel);
    };

    if (state.isLoading) {
        addHook({
            name: 'ON_VERIFICATION_READY',
            callback: () => {
                doSet();
            },
        });
    } else {
        doSet();
    }
};

/**
 * Schedule, or immediately perform a viewModel reset.
 * Resets fieldValidationErrors and previousViewModel as well.
 * @todo Return promise
 */
export const resetViewModel = () => {
    const state: ReduxState = store.getState();

    if (state.isLoading) {
        addHook({
            name: 'ON_VERIFICATION_READY',
            callback: (verificationResponse) => {
                doViewModelReset(store.dispatch)(verificationResponse);
            },
        });
    } else {
        doViewModelReset(store.dispatch)(state.verificationResponse);
    }
};

import React from 'react';
import { LoadingSpinnerComponent as Spinner } from '../LoadingSpinner/LoadingSpinnerComponent';

const LoadingScreenComponent = () => (
    <div className="sid-loading-screen">
        <Spinner />
    </div>
);

export { LoadingScreenComponent };

/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from '../../types/types';

export const memberMessages: SegmentSpecificMessages = {
    emailExplanation: 'Needed to send you your unique code',
    step: {
        personalInfo: {
            title: 'Unlock this Members-Only Offer',
            subtitle: "Verify you're a current member of an eligible organization.",
            howDoesVerifyingWorkDetails:
                `{companyName} has set up this special offer for all current members of select organizations.
            <br><br>
            {companyName} uses SheerID, a trusted partner, to verify that you are currently employed. SheerID only collects
            information that assists in verifying your eligibility. Additional information or documentation may be requested.`,
            tryAgain: {
                title: 'Check the information that you have provided',
                subtitle: 'The information you have provided should match your official documents.',
            },
        },
        docUpload: {
            title: 'We need more information',
            subtitle: 'Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.',
            howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying members.
            <br><br>
            Documents are used only to verify that your membership status is current and will be permanently deleted after review is finalized. They will not be shared with {companyName}`,
            uploadInstructions: 'Upload an official document that shows',
        },
        success: {
            title: "You've been verified",
            subtitle: 'Here is your personal coupon code. It is unique to you and can only be used once.',
            redirectButtonText: 'Use code now',
        },
    },
};

import React from 'react';
import { VerificationService } from '../../lib/types/types';

interface Props {
    verificationService: VerificationService;
}

const TestModeFlagComponent: React.SFC<Props> = ({ verificationService }) => (
    verificationService.programTheme && verificationService.programTheme.isTestMode
        ? (
            <div className="sid-test-mode-flag sid-l-container sid-l-horz-center">
            Test Mode
                <a
                    className="sid-test-mode-flag__link sid-link"
                    target="_blank"
                    href="http://developer.sheerid.com/getting-started#test-program"
                >
                (learn more)
                </a>
            </div>
        )
        : null
);

export { TestModeFlagComponent };

import React from 'react';
import { FormSelectChoice } from '../../../../lib/types/types';
interface InputSelectListProps {
    getItemProps: any;
    getMenuProps: any;
    highlightedIndex: number;
    itemToString: any;
    options: FormSelectChoice[];
}

const ROW_HEIGHT = 42;

const InputSelectList = ({ getItemProps, getMenuProps, highlightedIndex, options, itemToString }: InputSelectListProps) => {
    const fullHeight = options.length * ROW_HEIGHT;

    const item = (item: FormSelectChoice, index: number) => {
        return (
            <div
                className={`sid-input-select-list__item ${highlightedIndex === index ? 'sid-input-select-list__item--highlighted' : ''}`}
                key={index}
                {...getItemProps({
                    index,
                    item,
                })}
                >
                {itemToString(options[index])}
            </div>
        );
    };

    return(
        <div className={'sid-input-select-list__menu'}
            {...getMenuProps()}
            tabIndex={-1}
            style={{ height: Math.min(fullHeight, 280) }}
        >
            { options.map((option, index) => item(option, index)) }
        </div>
    );
};

export const InputSelectListComponent = InputSelectList;

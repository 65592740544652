import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';


const defaultSSOSubtitle = "Please sign in to your school's web portal. It will open in a new tab.";

const defaultSSOSubtitle2 = "Can't sign in to your school?";

const SSOPending = ({ logo, cancelSSO }) => (
    <div className="sid-header sid-l-horz-center">
        <div className="sid-l-horz-center">
            { logo }
        </div>
        <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
            <FormattedHTMLMessage id="step.sso.title" defaultMessage="Verify using your school credentials" />
        </div>
        <div className="sid-header__subtitle-one">
            <FormattedHTMLMessage
                id="step.sso.subtitle"
                defaultMessage={defaultSSOSubtitle}
            />
        </div>
        <div className="sid-l-space-top-xl" />
        <div className="sid-header__subtitle-two sid-h-small-text sid-l-space-top-md">
            <FormattedHTMLMessage
                id="step.sso.subtitle2"
                defaultMessage={defaultSSOSubtitle2}
            />
            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <button
                    onClick={cancelSSO}
                    type="submit"
                    className="sid-btn sid-btn--dark sid-l-full-width"
                    aria-label="submit"
                >
                    <FormattedHTMLMessage id="step.sso.cancel" defaultMessage="Upload Proof of Enrollment" />
                </button>
            </div>
        </div>
        <div className="sid-l-space-top-xl" />
    </div>
);

export const SSOPendingComponent = SSOPending;

/**
 * TODO - preamble
 */
import React from 'react';
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from 'react-intl';
import { produce } from 'immer';


import { VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { isFormFilled } from '../../lib/validators/validators';
import { setRef } from '../../lib/refs/refs';
import {
    VerificationService,
    FieldValidationErrors,
    ExtendedFieldValidationErrors,
    Organization,
    EmploymentPersonalInfoViewModel,
} from '../../lib/types/types';
import {
    handleEmailOnKeyDown,
    submitForm,
    updateFieldValidationErrorsByFieldId,
    updateViewModelOrganization,
    shouldCollectAddressFields,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from '../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { PhoneNumberComponent as PhoneNumber } from '../FormFields/PhoneNumber/PhoneNumberComponent';
import { FirstNameComponent as FirstName } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent as LastName } from '../FormFields/LastName/LastNameComponent';
import { EmailComponent as Email } from '../FormFields/Email/EmailComponent';
import { MarketConsentWrapperComponent as MarketConsentWrapper } from '../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent';
import { AddressComponent } from '../FormFields/Address/AddressComponent';
import { CityComponent } from '../FormFields/City/City';
import { StateComponent } from '../FormFields/State/State';
import { CompanyComponent } from '../FormFields/Company/CompanyComponent';
import { PostalCodeComponent } from '../FormFields/PostalCode/PostalCodeComponent';

import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { CountryComponentWrapper } from '../FormFields/Country/CountryComponentWrapper';
import { RewardsRemainingComponent } from '../RewardsRemaining/RewardsRemainingComponent';

interface StepEmploymentPersonalInfoComponentProps {
    intl: InjectedIntl;
    verificationService: VerificationService;
}

const StepEmploymentPersonalInfo = ({ intl, verificationService }: StepEmploymentPersonalInfoComponentProps) => {
    const viewModel = verificationService.viewModel as EmploymentPersonalInfoViewModel;
    const fieldValidationErrors: FieldValidationErrors & ExtendedFieldValidationErrors = verificationService.fieldValidationErrors;

    const updateEmploymentViewModel = (key: keyof EmploymentPersonalInfoViewModel, value: any) => {
        const nextState: EmploymentPersonalInfoViewModel = produce(viewModel, (draft: EmploymentPersonalInfoViewModel) => {
            (draft[key] as any) = value;
        });
        verificationService.updateViewModel(nextState);
    };

    return (
        <div id="sid-step-employment-personal-info" className="sid-l-container">
            <div className="sid-header">
                <div className="sid-l-horz-center">
                    <LogoComponent verificationService={verificationService} />
                </div>
                <div className="sid-header__title sid-l-horz-center">
                    <FormattedHTMLMessage id="step.personalInfo.title" defaultMessage="Unlock this Employment-Only Offer" />
                </div>

                <div className="sid-header__subtitle sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.subtitle"
                            defaultMessage="Verify you're a current employee at an eligible company." />
                    &nbsp;
                    <HowDoesVerifyingWork verificationService={verificationService} />
                    <RewardsRemainingComponent verificationService={verificationService} />
                </div>
            </div>

            <CountryComponentWrapper
                verificationService={verificationService}
                viewModel={viewModel}
                nextFocusField="organization"
                viewModelDraftDecorator={(draft, countryChoice) => {
                    const viewModel = draft as EmploymentPersonalInfoViewModel;

                    viewModel.shouldCollectAddress = shouldCollectAddressFields(countryChoice, verificationService.programTheme.config.countries);
                    if (! viewModel.shouldCollectAddress) {
                        viewModel.address1 = '';
                        viewModel.city = '';
                        viewModel.state = '';
                        viewModel.postalCode = '';
                        viewModel.fieldsToSkipValidation = ['address1', 'city', 'state', 'postalCode'];
                    } else {
                        viewModel.fieldsToSkipValidation = undefined;
                    }
                }}
            />

            <CompanyComponent
                value={(verificationService.viewModel as EmploymentPersonalInfoViewModel).organization}
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.organization}
                onChange={
                    (choice: Organization) => {
                        updateViewModelOrganization(choice, verificationService);
                        updateFieldValidationErrorsByFieldId('organization', choice, verificationService);
                    }
                }
            />

            <div className="sid-names">
                <FirstName
                    value={viewModel.firstName}
                    isErrored={!!fieldValidationErrors.firstName}
                    onChange={
                        (newValue) => {
                            updateEmploymentViewModel('firstName', newValue);
                            updateFieldValidationErrorsByFieldId('firstName', newValue, verificationService);
                        }
                    }
                />

                <LastName
                    value={viewModel.lastName}
                    isErrored={!!fieldValidationErrors.lastName}
                    onChange={
                        (newValue) => {
                            updateEmploymentViewModel('lastName', newValue);
                            updateFieldValidationErrorsByFieldId('lastName', newValue, verificationService);
                        }
                    }
                />
            </div>

            {
                shouldCollectAddressFields(viewModel.countryChoice, verificationService.programTheme.config.countries) ?
                <AddressComponent
                    isErrored={!!fieldValidationErrors.address1}
                    errorId={fieldValidationErrors.address1}
                    onChange={(newValue) => {
                        updateEmploymentViewModel('address1', newValue);
                        updateFieldValidationErrorsByFieldId('address1', newValue, verificationService);
                    }}
                    value={viewModel.address1}
                />
                :
                    null
            }

            {
                shouldCollectAddressFields(viewModel.countryChoice, verificationService.programTheme.config.countries) ?
                <div className="sid-thirds">
                    <CityComponent
                        isErrored={!!fieldValidationErrors.city}
                        errorId={fieldValidationErrors.city}
                        onChange={(newValue) => {
                            updateEmploymentViewModel('city', newValue);
                            updateFieldValidationErrorsByFieldId('city', newValue, verificationService);
                        }}
                        value={viewModel.city}
                    />

                    <StateComponent
                        isErrored={!!fieldValidationErrors.state}
                        errorId={fieldValidationErrors.state}
                        onChange={(newValue) => {
                            updateEmploymentViewModel('state', newValue);
                            updateFieldValidationErrorsByFieldId('state', newValue, verificationService);
                        }}
                        value={viewModel.state}
                    />

                    <PostalCodeComponent
                        isErrored={!!fieldValidationErrors.postalCode}
                        onChange={(newValue) => {
                            updateEmploymentViewModel('postalCode', newValue);
                            updateFieldValidationErrorsByFieldId('postalCode', newValue, verificationService);
                        }}
                        value={viewModel.postalCode}
                    />
                </div>
                :
                null
            }

            <Email
                value={viewModel.email}
                isErrored={!!fieldValidationErrors.email}
                explanation={
                    <FormattedHTMLMessage
                        id="emailExplanation"
                        defaultMessage="Needed to send you your unique code"
                    />
                }
                onChange={
                    (newValue) => {
                        updateEmploymentViewModel('email', newValue);
                        updateFieldValidationErrorsByFieldId('email', newValue, verificationService);
                    }
                }
                onKeyDown={event => handleEmailOnKeyDown(event)}
            />
            {
                verificationService.programTheme.isSmsNotifierConfigured || verificationService.programTheme.smsLoopEnabled ?
                    <PhoneNumber
                        isRequired={!!verificationService.programTheme.smsLoopEnabled}
                        value={viewModel.phoneNumber}
                        isErrored={!!fieldValidationErrors.phoneNumber}
                        onChange={
                            (newValue) => {
                                updateEmploymentViewModel('phoneNumber', newValue);
                                updateFieldValidationErrorsByFieldId('phoneNumber', newValue, verificationService);
                            }
                        }
                    />
                    : null
            }
            <MarketConsentWrapper
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.marketConsentValue}
                onChange={(newValue) => {
                    updateEmploymentViewModel('metadata', { ...verificationService.viewModel.metadata, marketConsentValue: newValue });
                    updateFieldValidationErrorsByFieldId('marketConsentValue', newValue, verificationService);
                }}
                viewModel={viewModel}
            />

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-wrapper__collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectEmployeePersonalInfo)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${!isFormFilled(viewModel, verificationService.formValidationOptions) ? 'sid-btn--disabled-like' : ''}`}
                        aria-label="submit"
                        aria-labelledby="verify-status-text"
                        ref={button => setRef('submitButton', button)}
                    >
                        <span id="verify-status-text">
                            <FormattedHTMLMessage id="verifyMyEmploymentStatus" defaultMessage="Verify My Employment Status" />
                        </span>
                    </button>
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepEmploymentPersonalInfoComponent = injectIntl(StepEmploymentPersonalInfo);

import React from 'react';
import { Dispatch } from 'redux';
import { connect, MapStateToProps, MapDispatchToProps, MergeProps } from 'react-redux';

import {
    DatabaseId,
    Locale,
    ProgramTheme,
    ReduxState,
    VerificationResponse,
    VerificationService,
    VerificationServiceFunctions,
    VerificationServiceValues,
    ViewModel,
    Messages,
    Segment,
} from '../lib/types/types';

import { logger } from '../lib/utils/logger/logger';
import { isMockingLoading } from '../lib/ServerApi/TestingRouteOverrides';
import { LoadingScreenComponent as LoadingScreen } from './LoadingScreen/LoadingScreenComponent';
import {
    createFetchNewVerificationRequest,
    createUpdateViewModel,
    createUpdateFieldValidationErrors,
    createSubmitStep,
    createFetchExistingVerificationRequest,
    createUpdateProgramTheme,
    createUpdateLocale,
} from '../lib/VerificationService/VerificationServiceActions';

import { getLocaleSafely } from '../lib/intl/intl';
import { getSafe } from '../lib/utils/objects';
import { NewVerificationInnerComponent } from './NewVerificationInnerComponent';

interface NewVerificationProps {
    programId: DatabaseId;
    messages?: Messages;
    verificationService?: VerificationService;
    trackingId?: string;
}
interface NewVerificationState {}

export class NewVerification extends React.Component<NewVerificationProps, NewVerificationState> {
    verificationService: VerificationService;

    constructor(props) {
        super(props);
        logger.info('NewVerificationComponent: constructing');

        if (this.props.trackingId && this.props.trackingId.length) {
            logger.info(`Attaching a trackingId to this verification request ${this.props.trackingId}`);
        } else {
            logger.info('No trackingId to associate with the verification request');
        }

        // Special-case, mocking the loading psuedo-step
        if (isMockingLoading()) {
            logger.info('Mocking loading screen. Not fetching new verification request.');
        } else {
            const currentSegment = getSafe<Segment>(() => this.props.verificationService.verificationResponse.segment);
            this.props.verificationService.fetchNewVerificationRequest(
                this.props.programId,
                currentSegment,
                this.props.verificationService.previousViewModel,
                this.props.trackingId,
            );
        }
    }

    public render() {
        if (this.props.verificationService.isLoading) {
            logger.info('NewVerificationComponent: VerificationService is loading');
            return <LoadingScreen />; // Note: we don't know locale yet
        }

        const programTheme: ProgramTheme = this.props.verificationService.programTheme;
        const verificationResponse: VerificationResponse = this.props.verificationService.verificationResponse;
        const viewModel: ViewModel = this.props.verificationService.viewModel;
        logger.info('NewVerificationComponent: verificationResponse, viewModel', verificationResponse, viewModel);

        const locale: Locale = getLocaleSafely(viewModel);

        let messages: Messages;
        // optional param `messages`. If we do not have it, all <FormattedMessages /> should use default fallback anyway.
        if (this.props.messages) {
            messages = this.props.messages;
        }

        logger.info(`NewVerificationComponent: locale: ${locale}, currentStep: ${verificationResponse.currentStep}, messages: `, messages);

        return (
            <NewVerificationInnerComponent
                locale={locale}
                messages={messages}
                programTheme={programTheme}
                verificationService={this.props.verificationService}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxState, NewVerificationProps, any> = (state: ReduxState, ownProps: NewVerificationProps): ReduxState => ({
    ...state,
});

const mapDispatchToProps: MapDispatchToProps<any, any> = (dispatch: Dispatch, ownProps: NewVerificationProps) => {
    return {
        fetchNewVerificationRequest: createFetchNewVerificationRequest(dispatch),
        fetchExistingVerificationRequest: createFetchExistingVerificationRequest(dispatch),
        updateViewModel: createUpdateViewModel(dispatch),
        updateLocale: createUpdateLocale(dispatch),
        updateProgramTheme: createUpdateProgramTheme(dispatch),
        updateFieldValidationErrors: createUpdateFieldValidationErrors(dispatch),
        submitStep: createSubmitStep(dispatch),
    };
};

// Return a VerificationService
const mergeProps: MergeProps<VerificationServiceValues & ReduxState, VerificationServiceFunctions, NewVerificationProps, any> = (
    propsFromState,
    propsFromDispatch,
    ownProps,
): NewVerificationProps => ({
    verificationService: {
        ...propsFromState,
        ...propsFromDispatch,
    },
    messages: propsFromState.messages,
    ...ownProps,
});

export const NewVerificationComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
 )(NewVerification);

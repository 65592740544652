import React from 'react';

import { StepActiveMilitaryPersonalInfoComponent } from './StepActiveMilitaryPersonalInfoComponent';
import { VerificationService } from '../../es6';

interface StepCollectMilitaryStatusComponentProps {
    verificationService: VerificationService;
}

// Collect status is identical to Active at this time.
const StepCollectMilitaryStatusComponent = ({ verificationService }: StepCollectMilitaryStatusComponentProps) => (
    <StepActiveMilitaryPersonalInfoComponent verificationService={verificationService} />
);

export { StepCollectMilitaryStatusComponent };

import React, { lazy, Suspense } from 'react';
import { FormattedHTMLMessage, injectIntl, FormattedMessage } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { setRef } from '../../../lib/refs/refs';
import { OptInInputComponent } from '../../FormInputs/OptInInput/OptInInputComponent';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';

const PhoneInput = lazy(() => import('react-phone-input-2'));
interface PhoneNumberProps {
    explanation?: string | JSX.Element;
    isRequired?: boolean;
    onKeyDown?: Function;
    onCarrierConsentChange?: Function;
    displaySmsConsent?: boolean;
    requireCarrierConsent?: boolean;
    carrierConsent?: boolean;
    internationalSupport?: boolean;
}

const PhoneNumber = ({
    value,
    isErrored,
    onChange,
    explanation,
    onCarrierConsentChange,
    intl,
    isRequired = false,
    onKeyDown = undefined,
    placeholder = '',
    displaySmsConsent = true,
    requireCarrierConsent = false,
    carrierConsent = false,
    internationalSupport = false,
}: FormFieldComponentProps & PhoneNumberProps) => {
    const getDefaultExplanation = () => (!isRequired
        ? (
            <FormattedHTMLMessage
                id="phoneNumberExplanation"
                defaultMessage="Optional - get text notifications of status updates"
            />
        )
        : '');

    return (
        <div className="sid-field sid-phone-number">
            <div className="sid-l-space-top-md" />
            <label htmlFor="sid-phone-number">
                <div className={`sid-field__label-with-explanation sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                    <div className={`sid-field__label${isRequired ? ' sid-field__label--required' : ''}`}>
                        <FormattedHTMLMessage
                            id="phoneNumber"
                            defaultMessage="Mobile Number"
                        />
                    </div>
                    <div className="sid-field__label-explanation">
                        {
                            explanation || getDefaultExplanation()
                        }
                    </div>
                </div>
            </label>
            {internationalSupport ? (
                <Suspense fallback={<InputTextComponent className="sid-phone-number__placeholder" id="phone-number-placeholder" isErrored={false}/>}>
                    <PhoneInput
                        country="us"
                        containerClass="react-tel-input sid-phone-number__wrapper"
                        inputClass={
                            `sid-text-input sid-phone-number__field sid-phone-number__field--intl ${isRequired ? 'sid-text-input--required' : ''}
                                    sid-l-full-width ${isErrored ? 'sid-text-input--error' : ''}`
                        }
                        autoFormat={false}
                        value={value}
                        onChange={value => onChange(value)}
                        placeholder={placeholder}
                        // @ts-ignore
                        ref={input => setRef('phoneNumber', input)}
                        inputProps={{
                            id: 'sid-phone-number',
                            name: 'sid-phone-number',
                            onKeyDown: e => (onKeyDown ? onKeyDown(e) : undefined),
                        }}
                    />
                </Suspense>
            ) : (
                <InputTextComponent
                    className="sid-phone-number__field"
                    id="phone-number"
                    isErrored={isErrored}
                    onChange={e => onChange(e.target.value)}
                    onKeyDown={e => (onKeyDown ? onKeyDown(e) : undefined)}
                    placeholder={placeholder || intl.formatMessage({ id: 'phoneUsPlaceholder', defaultMessage: '555-555-5555' })}
                    refId="phoneNumber"
                    required={isRequired}
                    type="tel"
                    value={value}
                />
            )}
            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidPhoneNumber"
                            defaultMessage="Invalid mobile number"
                        />
                    </div>
                ) : null
            }
            {displaySmsConsent && (
                <div className="sid-h-small-text sid-l-space-top-md sid-sms-consent">
                    <FormattedMessage
                        id="phoneNumberWarnMessage1"
                        defaultMessage="By submitting this form with a mobile number, you consent to receive SMS/text messages for verification purposes. Standard phone carrier text messaging (SMS) and data rates may apply."
                    />
                </div>
            )}
            {requireCarrierConsent && (
                <div className="sid-l-position sid-carrier-disclosure-consent">
                    <div className="sid-h-small-text sid-l-space-top-md">
                        <label>
                            <OptInInputComponent
                                isErrored={isErrored}
                                value={carrierConsent}
                                onChange={value => onCarrierConsentChange(value)}
                            />
                            <div className="sid-field__label sid-checkbox__label">
                                <FormattedMessage
                                    id="phoneNumberWarnMessage2"
                                    defaultMessage="You authorize your mobile service provider to disclose your mobile number and certain account information to SheerID, solely for identity verification and to avoid fraud."
                                />
                            </div>
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export const PhoneNumberComponent = injectIntl(PhoneNumber);

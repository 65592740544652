import { FormSelectChoice } from '../../types/types';

type compareLabelReturn = 1 | -1;

export const compareLabel = (a: FormSelectChoice<any>, b: FormSelectChoice<any>): compareLabelReturn => {
    let sortValue: compareLabelReturn;

    try {
        sortValue = a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
    } catch (e) {
        sortValue = 1;
    }

    return sortValue;
};

export const SortByLabel = (choices: FormSelectChoice<any, string>[]): FormSelectChoice<any, string>[] => choices.sort(compareLabel);

/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from '../../types/types';

export const medicalMessages: SegmentSpecificMessages = {
    emailExplanation: 'Needed to send you your unique code',
    step: {
        personalInfo: {
            title: 'Unlock this Medical Professional-Only Offer',
            subtitle: "Verify you're a licensed medical professional.",
            howDoesVerifyingWorkDetails: `To be eligible for this offer, you must (1) be a medical professional currently licensed in the United States
                    and (2) be a member of an eligible branch of the medical profession. Eligible branches are shown in the Status
                    selector of this form.<br><br>{companyName} uses SheerID, a trusted partner, to verify that you are a medical professional.
                    SheerID only collects information that assists in verifying your eligibility.
                    Additional information or documentation may be requested.`,
            tryAgain: {
                title: 'Check your Name, Birthdate & Organization',
                subtitle: 'Your first and last name, birthdate and organization must match what is on your official documents.',
            },
        },
        docUpload: {
            title: 'We need more information',
            subtitle: 'Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.',
            howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying
                    medical professionals.<br><br>Documents are used only for verifying your medical professional status is
                    current and will be permanently deleted after review is finalized. They will not be shared with {companyName}.`,
            uploadInstructions: 'Upload an official document that shows',
        },
        success: {
            title: "You've been verified",
            subtitle: 'Here is your personal coupon code. It is unique to you and can only be used once.',
            redirectButtonText: 'Use code now',
        },
    },
};

import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { PostalCodeInputComponent } from '../../FormInputs/PostalCodeInput/PostalCodeInputComponent';

interface PassedProps {
    explanation?: string | JSX.Element;
}

const PostalCodeComponent = ({
    value, isErrored, onChange, explanation,
}: FormFieldComponentProps & PassedProps) => (
    <div className="sid-field sid-postal-code">
        <div className="sid-l-space-top-md" />
        <label htmlFor="sid-postal-code">
            <div className="sid-field__label-with-explanation sid-l-space-btm-sm">
                <div className="sid-field__label sid-field__label--required">
                    <FormattedMessage
                        id="postalCode"
                        defaultMessage="Postal Code"
                    />
                </div>
                {
                    explanation ? (<div className="sid-field__label-explanation">{explanation}</div>) : null
                }
            </div>
        </label>
        <PostalCodeInputComponent
            value={value}
            isValid={!isErrored}
            onChange={onChange}
        />
        {
            isErrored ? (
                <div className="sid-field-error">
                    <FormattedHTMLMessage
                        id="errorId.invalidPostalCode"
                        defaultMessage="Invalid postal code"
                    />
                </div>
            ) : null
        }
    </div>
);

export { PostalCodeComponent };

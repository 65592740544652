

import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { VerificationService } from '../../lib/types/types';
import { assertValidSegmentName } from '../../lib/types/assertions';
import { SegmentEnum, SubSegmentEnum } from '../../lib/types/runtimeTypes';

interface Props {
    verificationService: VerificationService;
}

const AcceptableUploadsComponent: React.SFC<Props> = ({ verificationService }) => {
    const { segment } = verificationService.verificationResponse;
    const { subSegment } = verificationService.verificationResponse;
    assertValidSegmentName(segment);

    let acceptableUploads: JSX.Element;

    switch (segment) {
        case SegmentEnum.STUDENT:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.student.idCard"
                            defaultMessage="School ID card with expiration date"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.student.classSchedule"
                            defaultMessage="Class schedule"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.student.tuitionReceipt"
                            defaultMessage="Tuition receipt"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.TEACHER:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.teacher.idCard"
                            defaultMessage="Teacher ID card with valid date"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.teacher.payStub"
                            defaultMessage="Pay stub from within last 60 days"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.MEMBER:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.member.idCard"
                            defaultMessage="Membership Document"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.member.payStub"
                            defaultMessage="Pay stub from within last 90 days"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.SENIOR:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.senior.birthCertificate"
                            defaultMessage="Your birth certificate"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.senior.driversLicense"
                            defaultMessage="Your drivers license"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.senior.passport"
                            defaultMessage="Your passport"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.senior.stateId"
                            defaultMessage="Your state-issued ID"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.AGE:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.age.birthCertificate"
                            defaultMessage="Your birth certificate"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.age.driversLicense"
                            defaultMessage="Your drivers license"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.age.passport"
                            defaultMessage="Your passport"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.age.stateId"
                            defaultMessage="Your state-issued ID"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.MILITARY:
            switch (subSegment) {
                case SubSegmentEnum.ACTIVE_DUTY:
                    acceptableUploads = (
                        <ul className="sid-acceptable-uploads-list">
                            {/* TODO - translate. Also TODO - do these come from program config? */}
                            <li className="sid-acceptable-uploads-list__li">
                                <FormattedHTMLMessage
                                    id="step.docUpload.acceptableUploads.military.activeDuty"
                                    defaultMessage="Any document that proves you are currently serving as active duty
                                        in the armed forces for 30 days or more"
                                />
                            </li>
                        </ul>
                    );
                    break;
                case SubSegmentEnum.VETERAN:
                    acceptableUploads = (
                        <ul className="sid-list">
                            {/* TODO - translate. Also TODO - do these come from program config? */}
                            <li className="sid-li">
                                <FormattedHTMLMessage
                                    id="step.docUpload.acceptableUploads.military.veteran"
                                    defaultMessage="Any document that proves you met the qualifications of
                                        military service and were honorably discharged"
                                />
                            </li>
                        </ul>
                    );
                    break;
                case SubSegmentEnum.RESERVIST:
                    acceptableUploads = (
                        <ul className="sid-list">
                            {/* TODO - translate. Also TODO - do these come from program config? */}
                            <li className="sid-li">
                                <FormattedHTMLMessage
                                    id="step.docUpload.acceptableUploads.military.reservistA"
                                    defaultMessage="Any document that shows you are currently serving in a reserve
                                        component of the armed forces"
                                />
                            </li>
                        </ul>
                    );
                    break;
                case SubSegmentEnum.MILITARY_RETIREE:
                    acceptableUploads = (
                        <ul className="sid-list">
                            {/* TODO - translate. Also TODO - do these come from program config? */}
                            <li className="sid-li">
                                <FormattedHTMLMessage
                                    id="step.docUpload.acceptableUploads.military.retiree"
                                    defaultMessage="Any document that proves you are a retiree from the armed forces"
                                />
                            </li>
                        </ul>
                    );
                    break;
                case SubSegmentEnum.MILITARY_FAMILY:
                    acceptableUploads = (
                        <ul className="sid-list">
                            <li className="sid-li">
                                <FormattedHTMLMessage
                                    id="step.docUpload.acceptableUploads.military.dependent"
                                    defaultMessage="Any document that proves you are a Registered Military Dependent"
                                />
                            </li>
                        </ul>
                    );
                    break;
                default:
                    return null;
            }
            break;
        case SegmentEnum.FIRST_RESPONDER:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.firstResponder.idCard"
                            defaultMessage="ID Card"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.firstResponder.payStub"
                            defaultMessage="Pay stub from within last 60 days"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.firstResponder.letter"
                            defaultMessage="An official letter"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.MEDICAL:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.medical.licenseCertificate"
                            defaultMessage="Certificate of license"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.medical.idCard"
                            defaultMessage="Nursing ID card with expiration date"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.medical.photoPayStub"
                            defaultMessage="Photo ID and recent Pay Stub"
                        />
                    </li>
                </ul>
            );
            break;
        case SegmentEnum.EMPLOYMENT:
            acceptableUploads = (
                <ul className="sid-acceptable-uploads-list">
                    {/* TODO - translate. Also TODO - do these come from program config? */}
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.employment.employeeIdCard"
                            defaultMessage="Employee ID Card"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.employment.payStub"
                            defaultMessage="Pay stub from within last 60 days"
                        />
                    </li>
                    <li className="sid-acceptable-uploads-list__li">
                        <FormattedHTMLMessage
                            id="step.docUpload.acceptableUploads.employment.officialLetter"
                            defaultMessage="An official letter"
                        />
                    </li>
                </ul>
            );
            break;
        default:
            return null;
    }

    return (
        <div>
            <span>
                <strong>
                    <FormattedMessage id="step.docUpload.acceptableUploadExamples" defaultMessage="Acceptable examples include:" />
                </strong>
            </span>
            {acceptableUploads}
        </div>
    );
};

export { AcceptableUploadsComponent };

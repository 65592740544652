import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { getSafe } from '../../lib/utils/objects';
import { MaxReviewTime, VerificationService, PendingResponse } from '../../es6';
import { getEstAndMaxReviewTimes } from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

interface ReviewPendingProps {
    intl: any;
    logo: JSX.Element;
    verificationService: VerificationService;
    maxReviewTime?: MaxReviewTime; // deprecated
}

export const timesChanged = (previousResponse: PendingResponse, currentResponse: PendingResponse) => {
    const previousEst = getSafe(() => previousResponse.estimatedReviewTime);
    const previousMax = getSafe(() => previousResponse.maxReviewTime);
    const newEst = getSafe(() => currentResponse.estimatedReviewTime);
    const newMax = getSafe(() => currentResponse.maxReviewTime);
    return (previousEst !== undefined && previousEst !== newEst) || (previousMax !== undefined && previousMax !== newMax);
};

const ReviewPending = ({ intl, logo, verificationService }: ReviewPendingProps) => {
    const verificationResponse: PendingResponse = (verificationService.verificationResponse as PendingResponse);
    const [isAlerting, setAlertState] = React.useState(false);

    if (!isAlerting && timesChanged(verificationService.previousVerificationResponse as PendingResponse, verificationResponse)) {
        setAlertState(true);
    }

    const estAndMaxTimes = getEstAndMaxReviewTimes({ ...verificationResponse }, verificationService.programTheme, intl);

    return (
        <div className="sid-header sid-l-horz-center" data-testid="sid-review-pending">
            <div className="sid-l-horz-center">
                { logo }
            </div>
            <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
                <FormattedHTMLMessage id="step.pending.titleReview" defaultMessage="Reviewing" />
            </div>
            <div className="sid-header__subtitle-one">
                {
                    isAlerting ?
                        <FormattedHTMLMessage
                            id="step.pending.subtitleAlt"
                            defaultMessage="SheerID is reviewing your documents, to confirm your status."
                        />
                        :
                        <FormattedHTMLMessage
                            id="step.pending.turnaroundTime"
                            defaultMessage="Turnaround time for your language is typically {estReviewTime}, and in rare times of high volume may take up to {maxReviewTime}."
                            values={estAndMaxTimes}
                        />
                }
            </div>
            <div className="sid-header__subtitle-two sid-l-space-top-md">
                <FormattedHTMLMessage
                    id="step.pending.subtitle2"
                    defaultMessage="Wait here or let us notify you of the outcome."
                />
            </div>
            <div className="sid-header__subtitle-three sid-l-space-top-md">
                <FormattedHTMLMessage
                    id="step.pending.subtitle3"
                    defaultMessage="You will be contacted via email."
                />
            </div>
            {
                isAlerting ?
                <div className="sid-pending-red-box">
                    <ul>
                        <li><FormattedHTMLMessage id="step.pending.docDiffLang" defaultMessage="We identified your document is in a different language." /></li>
                        <li>
                            <FormattedHTMLMessage
                                id="step.pending.turnaroundTime"
                                defaultMessage="Turnaround time for your language is typically {estReviewTime}, and in rare times of high volume may take up to {maxReviewTime}."
                                values={estAndMaxTimes}
                            />
                        </li>
                    </ul>
                </div>
                :
                null
            }
            <div className="sid-l-space-top-xl" />
        </div>
    );
};
export const ReviewPendingComponent = ReviewPending;

import React from 'react';
import { setRef } from '../../../lib/refs/refs';

interface OptInInputProps {
    isErrored: boolean;
    onChange: Function;
    value: boolean;
}

const OptInInput = ({
    isErrored,
    onChange,
    value,
}: OptInInputProps) => (
    <div className="sid-checkbox">
        <input
            className="sid-checkbox__input"
            id="sid-opt-in"
            name="sid-opt-in"
            type="checkbox"
            checked={value}
            ref={ref => setRef('optIn', ref)}
            onChange={e => onChange(e.target.checked)}
        />
        <span className={`sid-checkbox__input-like ${isErrored ? 'sid-checkbox__input-like--error' : ''}`} />
    </div>
);

export const OptInInputComponent = OptInInput;

/**
 * Step Teacher Personal Info Component
 * TODO - We need to import more from es6
 */
import React from 'react';
import { FormattedHTMLMessage, injectIntl, InjectedIntl } from 'react-intl';
import { produce } from 'immer';

import { VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { isFormFilled } from '../../lib/validators/validators';
import { hasFailedInstantMatch } from '../../lib/VerificationService/VerificationServiceHelpers';
import { setRef } from '../../lib/refs/refs';
import { shouldCollectPostalCode } from '../../lib/organization/organization';
import {
    VerificationService,
    FieldValidationErrors,
    ExtendedFieldValidationErrors,
    FormSelectChoice,
    TeacherPersonalInfoViewModel,
    Organization,
    TeacherPersonalInfoResponse,
    Locale,
} from '../../lib/types/types';
import {
    handleEmailOnKeyDown,
    submitForm,
    updateFieldValidationErrorsByFieldId,
    updateViewModelOrganization,
    getAvailableLocaleChoices,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from '../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { MarketConsentWrapperComponent as MarketConsentWrapper } from '../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent';
import { PhoneNumberComponent as PhoneNumber } from '../FormFields/PhoneNumber/PhoneNumberComponent';
import { FirstNameComponent as FirstName } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent as LastName } from '../FormFields/LastName/LastNameComponent';
import { EmailComponent as Email } from '../FormFields/Email/EmailComponent';
import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { PostalCodeComponent } from '../FormFields/PostalCode/PostalCodeComponent';
import { ChangeLocaleComponent } from '../FormFields/ChangeLocale/ChangeLocaleComponent';
import { TeacherSchoolComponent } from '../FormFields/TeacherSchool/TeacherSchoolComponent';
import { CountryComponentWrapper } from '../FormFields/Country/CountryComponentWrapper';
import { RewardsRemainingComponent } from '../RewardsRemaining/RewardsRemainingComponent';


interface StepTeacherPersonalInfoComponentProps {
    intl: InjectedIntl;
    verificationService: VerificationService;
}

const StepTeacherPersonalInfo = ({ intl, verificationService }: StepTeacherPersonalInfoComponentProps) => {
    const viewModel = verificationService.viewModel as TeacherPersonalInfoViewModel;
    const fieldValidationErrors: FieldValidationErrors & ExtendedFieldValidationErrors = verificationService.fieldValidationErrors;
    const verificationResponse = verificationService.verificationResponse as TeacherPersonalInfoResponse;
    const failedInstantMatch = hasFailedInstantMatch(verificationResponse);
    const localeChoices: FormSelectChoice<Locale, string>[] = getAvailableLocaleChoices(verificationService.programTheme, intl);
    const defaultLocaleChoice: FormSelectChoice<Locale, string> = { value: 'en-US', label: 'English' };

    const updateTeacherViewModel = (key: keyof TeacherPersonalInfoViewModel, value: any) => {
        const nextState: TeacherPersonalInfoViewModel = produce(viewModel, (draft: TeacherPersonalInfoViewModel) => {
            (draft[key] as any) = value;
        });
        verificationService.updateViewModel(nextState);
    };

    const updateLocale = (localeChoice: FormSelectChoice<Locale, string>) => {
        const nextState: TeacherPersonalInfoViewModel = produce(viewModel, (draft: TeacherPersonalInfoViewModel) => {
            draft.localeChoice = localeChoice;
        });
        verificationService.updateLocale(
            nextState,
            verificationService.programTheme,
            verificationService.verificationResponse.segment,
        );
    };

    return (
        <div id="sid-step-teacher-personal-info" className="sid-l-container">


            { failedInstantMatch ?
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.title" defaultMessage="Check Your Name & Email" />
                    </div>
                    <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.subtitle"
                            defaultMessage="Your full name and email address must match what is on record with your school." />
                    </div>
                </div>
            :
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.title" defaultMessage="Unlock this Teaching Staff-Only Offer" />
                    </div>
                    <div className="sid-header__subtitle sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.subtitle"
                            defaultMessage="Verify you're a teacher, faculty or staff at a K-12 or degree-granting university." />
                            &nbsp;
                            <HowDoesVerifyingWork verificationService={verificationService} />
                            <RewardsRemainingComponent verificationService={verificationService} />
                    </div>
                </div>
            }

            {
                localeChoices.length > 1 ?
                    <ChangeLocaleComponent
                        options={localeChoices}
                        value={viewModel.localeChoice || defaultLocaleChoice}
                        isErrored={false}
                        onChange={(localeChoice: FormSelectChoice<Locale, string>) =>
                            updateLocale(localeChoice)}
                    />
                : null
            }

            <CountryComponentWrapper
                verificationService={verificationService}
                viewModel={viewModel}
                nextFocusField="organization"
            />

            <TeacherSchoolComponent
                value={(verificationService.viewModel as TeacherPersonalInfoViewModel).organization}
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.organization}
                onChange={
                    (choice: Organization) => {
                        updateViewModelOrganization(choice, verificationService);
                        updateFieldValidationErrorsByFieldId('organization', choice, verificationService);
                    }
                }
            />

            <div className="sid-names">
                <FirstName
                    value={viewModel.firstName}
                    isErrored={!!fieldValidationErrors.firstName}
                    onChange={
                        (newValue) => {
                            updateTeacherViewModel('firstName', newValue);
                            updateFieldValidationErrorsByFieldId('firstName', newValue, verificationService);
                        }
                    }
                />

                <LastName
                    value={viewModel.lastName}
                    isErrored={!!fieldValidationErrors.lastName}
                    onChange={
                        (newValue) => {
                            updateTeacherViewModel('lastName', newValue);
                            updateFieldValidationErrorsByFieldId('lastName', newValue, verificationService);
                        }
                    }
                />
            </div>

            <Email
                value={viewModel.email}
                isErrored={!!fieldValidationErrors.email}
                explanation={
                    <FormattedHTMLMessage
                        id="emailExplanation"
                        defaultMessage="Must be your school-issued email address"
                    />
                }
                onChange={
                    (newValue) => {
                        updateTeacherViewModel('email', newValue);
                        updateFieldValidationErrorsByFieldId('email', newValue, verificationService);
                    }
                }
                onKeyDown={event => handleEmailOnKeyDown(event)}
            />

            {
                verificationService.programTheme.isSmsNotifierConfigured || verificationService.programTheme.smsLoopEnabled ?
                    <PhoneNumber
                        isRequired={!!verificationService.programTheme.smsLoopEnabled}
                        value={viewModel.phoneNumber}
                        isErrored={!!fieldValidationErrors.phoneNumber}
                        onChange={
                            (newValue) => {
                                updateTeacherViewModel('phoneNumber', newValue);
                                updateFieldValidationErrorsByFieldId('phoneNumber', newValue, verificationService);
                            }
                        }
                    />
                : null
            }
                {
                    shouldCollectPostalCode(verificationService.programTheme, viewModel) ?
                    <PostalCodeComponent
                        isErrored={!!fieldValidationErrors.postalCode}
                        onChange={(newValue) => {
                            updateTeacherViewModel('postalCode', newValue);
                            updateFieldValidationErrorsByFieldId('postalCode', newValue, verificationService);
                        }}
                        value={viewModel.postalCode}
                    />
                    :
                    null
                }

            <MarketConsentWrapper
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.marketConsentValue}
                onChange={(newValue) => {
                    updateTeacherViewModel('metadata', { ...verificationService.viewModel.metadata, marketConsentValue: newValue });
                    updateFieldValidationErrorsByFieldId('marketConsentValue', newValue, verificationService);
                }}
                viewModel={viewModel}
            />

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-btn-collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectTeacherPersonalInfo)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${!isFormFilled(viewModel, verificationService.formValidationOptions) ? 'sid-btn--disabled-like' : ''}`}
                        aria-labelledby="verify-status-text"
                        ref={button => setRef('submitButton', button)}
                    >
                        <span id="verify-status-text">
                            { failedInstantMatch ?
                                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
                            :
                                <FormattedHTMLMessage id="verifyMyTeacherStatus" defaultMessage="Verify My Teacher Status" />
                            }
                        </span>
                    </button>
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepTeacherPersonalInfoComponent = injectIntl(StepTeacherPersonalInfo);

/**
 * TODO - preamble
 */
import React, { lazy, Suspense } from 'react';

import { VerificationStep, VerificationService } from '../lib/types/types';
import { VerificationSteps, VerificationStepsEnum } from '../lib/types/runtimeTypes';
import { logger } from '../lib/utils/logger/logger';
import { getOverridenComponent } from '../lib/componentOverride/componentOverride';

import { StepStudentPersonalInfoComponent } from './StepStudentPersonalInfo/StepStudentPersonalInfoComponent';
import { StepSeniorPersonalInfoComponent } from './StepSeniorPersonalInfo/StepSeniorPersonalInfoComponent';
import { StepAgePersonalInfoComponent } from './StepAgePersonalInfo/StepAgePersonalInfoComponent';
import { StepTeacherPersonalInfoComponent } from './StepTeacherPersonalInfo/StepTeacherPersonalInfoComponent';
import { StepMemberPersonalInfoComponent } from './StepMemberPersonalInfo/StepMemberPersonalInfoComponent';
import { StepActiveMilitaryPersonalInfoComponent } from './StepMilitaryPersonalInfo/StepActiveMilitaryPersonalInfoComponent';
import { StepInactiveMilitaryPersonalInfoComponent } from './StepMilitaryPersonalInfo/StepInactiveMilitaryPersonalInfoComponent';
import { StepFirstResponderPersonalInfoComponent } from './StepFirstResponderPersonalInfo/StepFirstResponderPersonalInfoComponent';
import { StepMedicalProfessionalPersonalInfoComponent } from './StepMedicalProfessionalPersonalInfo/StepMedicalProfessionalPersonalInfoComponent';
import { StepEmploymentPersonalInfoComponent } from './StepEmploymentPersonalInfo/StepEmploymentPersonalInfoComponent';
import { StepDriverLicensePersonalInfoComponent } from './StepIdentityPersonalInfo/StepDriverLicensePersonalInfoComponent';
import { StepGeneralIdentityPersonalInfoComponent } from './StepIdentityPersonalInfo/StepGeneralIdentityPersonalInfoComponent';
import { StepHybridIdentityPersonalInfoComponent } from './StepIdentityPersonalInfo/StepHybridIdentityPersonalInfoComponent';
import { StepCollectMilitaryStatusComponent } from './StepMilitaryPersonalInfo/StepCollectMilitaryStatusComponent';
import { StepSocialSecurityNumber } from './StepSocialSecurityNumber/StepSocialSecurityNumber';
import { StepErrorComponent } from './StepErrorComponent';
import { StepSSOComponent } from './StepSSO/StepSSOComponent';
import { LoadingScreenComponent as LoadingScreen } from './LoadingScreen/LoadingScreenComponent';

// Lazy loaded steps
// tslint:disable space-in-parens
const StepSuccessComponent = lazy(() => import(/* webpackChunkName: "sheerid-secondary-components", webpackPrefetch: true */'./StepSuccess/index'));
const StepPendingComponent = lazy(() => import(/* webpackChunkName: "sheerid-secondary-components", webpackPrefetch: true */'./StepPending/index'));
const StepSMSLoopComponent = lazy(() => import(/* webpackChunkName: "sheerid-secondary-components", webpackPrefetch: true */'./StepSMSLoop/index'));
const StepEmailLoopComponent = lazy(() => import(
    /* webpackChunkName: "sheerid-secondary-components", webpackPrefetch: true */'./StepEmailLoop/index'));
const StepDocUploadComponent = lazy(() => import(
    /* webpackChunkName: "sheerid-secondary-components", webpackPrefetch: true */'./StepDocUpload/index'));
// tslint:enable space-in-parens

const defaultComponents = {
    StepStudentPersonalInfoComponent,
    StepSeniorPersonalInfoComponent,
    StepAgePersonalInfoComponent,
    StepTeacherPersonalInfoComponent,
    StepMemberPersonalInfoComponent,
    StepCollectMilitaryStatusComponent,
    StepActiveMilitaryPersonalInfoComponent,
    StepInactiveMilitaryPersonalInfoComponent,
    StepFirstResponderPersonalInfoComponent,
    StepMedicalProfessionalPersonalInfoComponent,
    StepEmploymentPersonalInfoComponent,
    StepDriverLicensePersonalInfoComponent,
    StepGeneralIdentityPersonalInfoComponent,
    StepHybridIdentityPersonalInfoComponent,
    StepSocialSecurityNumber,
    StepSuccessComponent,
    StepDocUploadComponent,
    StepPendingComponent,
    StepSSOComponent,
    StepErrorComponent,
    StepSMSLoopComponent,
    StepEmailLoopComponent,
};

const getComponent = (componentName) => {
    logger.info('getComponent', componentName);
    const overriddenComponent = getOverridenComponent(componentName);
    if (overriddenComponent) {
        return overriddenComponent;
    }

    return defaultComponents[componentName];
};

const getLoadingScreen = () => <LoadingScreen/>;
interface Props {
    stepToLoad: VerificationStep;
    verificationService: VerificationService;
}

const LoadStepComponent = ({ stepToLoad, verificationService }: Props) => {
    logger.info(`LoadStepComponent: rendering step: ${stepToLoad}`);
    let Component;
    let isSecondaryStep = false;

    switch (stepToLoad) {
        case VerificationStepsEnum.collectStudentPersonalInfo:
            Component = getComponent('StepStudentPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectSeniorPersonalInfo:
            Component = getComponent('StepSeniorPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectAgePersonalInfo:
            Component = getComponent('StepAgePersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectTeacherPersonalInfo:
            Component = getComponent('StepTeacherPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectMemberPersonalInfo:
            Component = getComponent('StepMemberPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectMilitaryStatus:
            Component = getComponent('StepCollectMilitaryStatusComponent');
            break;
        case VerificationStepsEnum.collectActiveMilitaryPersonalInfo:
            Component = getComponent('StepActiveMilitaryPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectInactiveMilitaryPersonalInfo:
            Component = getComponent('StepInactiveMilitaryPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectFirstResponderPersonalInfo:
            Component = getComponent('StepFirstResponderPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectMedicalProfessionalPersonalInfo:
            Component = getComponent('StepMedicalProfessionalPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectEmployeePersonalInfo:
            Component = getComponent('StepEmploymentPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectDriverLicensePersonalInfo:
            Component = getComponent('StepDriverLicensePersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectGeneralIdentityPersonalInfo:
            Component = getComponent('StepGeneralIdentityPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectHybridIdentityPersonalInfo:
            Component = getComponent('StepHybridIdentityPersonalInfoComponent');
            break;
        case VerificationStepsEnum.collectSocialSecurityNumber:
            Component = getComponent('StepSocialSecurityNumber');
            break;
        case VerificationStepsEnum.success:
            Component = getComponent('StepSuccessComponent');
            isSecondaryStep = true;
            break;
        case VerificationStepsEnum.docUpload:
            Component = getComponent('StepDocUploadComponent');
            isSecondaryStep = true;
            break;
        case VerificationStepsEnum.pending:
            Component = getComponent('StepPendingComponent');
            isSecondaryStep = true;
            break;
        case VerificationStepsEnum.sso:
            Component = getComponent('StepSSOComponent');
            break;
        case VerificationStepsEnum.smsLoop:
            Component = getComponent('StepSMSLoopComponent');
            isSecondaryStep = true;
            break;
        case VerificationStepsEnum.emailLoop:
            Component = getComponent('StepEmailLoopComponent');
            isSecondaryStep = true;
            break;
        // If explicitly trying to render the error component, such as when testing/styling
        case VerificationStepsEnum.error:
            Component = getComponent('StepErrorComponent');
            break;
        default:
            const msg = `Error: Unknown step to render in LoadStepComponent.
                Unable to render step name "${stepToLoad}". Possible steps are [${VerificationSteps.join(', ')}]`;
            logger.error(msg, 'Unknown step in LoadStepComponent');
            return <StepErrorComponent verificationService={verificationService} errorId="invalidStep" />;
    }

    return (!isSecondaryStep ?
        <Component verificationService={verificationService} /> :
        <Suspense fallback={getLoadingScreen()}><Component verificationService={verificationService} /></Suspense>
    );
};

export { LoadStepComponent };

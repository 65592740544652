import Fingerprint2 from 'fingerprintjs2';
import { getOptions } from '../../options/options';

/**
 * @description The user's device fingerprint
 */
let fingerprint: string;

/**
 * @description Get the digital fingerprint hash for a user, based on various digital signals.
 *              Recommended: call this at least 500ms after page ready
 * @private
 */
export const getFingerprint = async (): Promise<string> => {
    if (!getOptions().useFingerprinting) {
        return Promise.resolve(undefined);
    }

    if (fingerprint) {
        return Promise.resolve(fingerprint);
    }

    return Fingerprint2.getPromise({}).then((components) => {
        const values = components.map(component => component.value);
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        fingerprint = murmur;
        return fingerprint;
    });
};

import React from 'react';
import ReactDom from 'react-dom';
import { IntlProvider } from 'react-intl';

import { DiscreteFieldDefault } from './DiscreteFieldDefault';
import { TypeaheadComponent } from '../components/FormInputs/Typeahead/TypeaheadComponent';
import { Country } from '../es6';

export class StudentTypeaheadField extends DiscreteFieldDefault {
    countryCode?: string;
    programId: string;

    constructor(element: HTMLElement) {
        super(element);
        // In case the 'new' keyword isn't used by es5 devs
        if (!(this instanceof StudentTypeaheadField)) {
            return new StudentTypeaheadField(element);
        }
    }

    setCountry(countryCode: string) {
        this.countryCode = countryCode;
        this.render();
    }

    getCountry() {
        return this.countryCode;
    }

    setProgramId(programId: string) {
        this.programId = programId;
        this.render();
    }

    getProgramId() {
        return this.programId;
    }

    render() {
        ReactDom.render(
            <IntlProvider locale={this.locale} key={this.locale} messages={this.messages}>
                <TypeaheadComponent
                    countryCode={this.countryCode as Country}
                    onChange={val => this.doChange(val)}
                    programId={this.programId}
                    value={this.value}
                    inputHtmlId="sid-college-name"
                />
            </IntlProvider>,
            this.mountPoint,
        );
    }
}

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';

export const FirstName = ({
    value,
    isErrored,
    onChange,
    intl,
    placeholder = '',
}: FormFieldComponentProps) => (
    <div className="sid-field sid-first-name">
        <div className="sid-l-space-top-md" />
        <label htmlFor="sid-first-name">
            <div className={`sid-field__label sid-field__label--required sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                <FormattedHTMLMessage
                    id="firstName"
                    defaultMessage="First name"
                />
            </div>
        </label>

        <InputTextComponent
            id="first-name"
            isErrored={isErrored}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder || intl.formatMessage({ id: 'firstNamePlaceholder', defaultMessage: 'First Name*' })}
            refId="firstName"
            value={value}
            required
        />

        {
            isErrored ? (
                <div className="sid-field-error">
                    <FormattedHTMLMessage
                        id="errorId.invalidFirstName"
                        defaultMessage="Invalid first name"
                    />
                </div>
            ) : null
        }
    </div>
);

export const FirstNameComponent = injectIntl(FirstName);

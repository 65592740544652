/**
 * TODO - preamble
 */
import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { produce } from 'immer';

import { VerificationStepsEnum, MilitaryStatusDefaultMessagesEnum } from '../../lib/types/runtimeTypes';
import { isFormFilled } from '../../lib/validators/validators';
import { hasFailedInstantMatch } from '../../lib/VerificationService/VerificationServiceHelpers';
import { setRef, resetRefs } from '../../lib/refs/refs';
import { logger } from '../../lib/utils/logger/logger';
import {
    VerificationService,
    FieldValidationErrors,
    ExtendedFieldValidationErrors,
    FormSelectChoice,
    Organization,
    ActiveMilitaryPersonalInfoViewModel,
    ActiveMilitaryPersonalInfoResponse,
} from '../../lib/types/types';
import {
    handleEmailOnKeyDown,
    submitForm,
    updateFieldValidationErrorsByFieldId,
    updateViewModelOrganization,
    getAvailableMilitaryStatuses,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { CountryComponentWrapper } from '../FormFields/Country/CountryComponentWrapper';
import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from '../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { PhoneNumberComponent as PhoneNumber } from '../FormFields/PhoneNumber/PhoneNumberComponent';
import { FirstNameComponent as FirstName } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent as LastName } from '../FormFields/LastName/LastNameComponent';
import { EmailComponent as Email } from '../FormFields/Email/EmailComponent';
import { BirthDateComponent as BirthDate } from '../FormFields/BirthDate/BirthDateComponent';
import { MilitaryStatusComponent as MilitaryStatus } from '../FormFields/MilitaryStatus/MilitaryStatusComponent';
import { BranchOfServiceComponent as BranchOfService } from '../FormFields/BranchOfService/BranchOfServiceComponent';
import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { MarketConsentWrapperComponent as MarketConsentWrapper } from '../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent';
import { RewardsRemainingComponent } from '../RewardsRemaining/RewardsRemainingComponent';


interface StepActiveMilitaryPersonalInfoComponentProps {
    intl: any;
    verificationService: VerificationService;
}

export const StepActiveMilitaryPersonalInfo = ({ intl, verificationService }: StepActiveMilitaryPersonalInfoComponentProps) => {
    const viewModel = verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel;
    const previousViewModel = verificationService.previousViewModel as ActiveMilitaryPersonalInfoViewModel;
    const fieldValidationErrors: FieldValidationErrors & ExtendedFieldValidationErrors = verificationService.fieldValidationErrors;
    const verificationResponse = verificationService.verificationResponse as ActiveMilitaryPersonalInfoResponse;
    const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

    const availableStatuses = getAvailableMilitaryStatuses(verificationService, intl);

    const updateActiveMilitaryViewModel = (key: keyof ActiveMilitaryPersonalInfoViewModel, value: any) => {
        const nextState: ActiveMilitaryPersonalInfoViewModel = produce(viewModel, (draft: ActiveMilitaryPersonalInfoViewModel) => {
            (draft[key] as any) = value;

            // If there are no status choices, don't validate
            if (!availableStatuses) {
                draft.fieldsToSkipValidation = ['status'];
            }
        });
        verificationService.updateViewModel(nextState);
    };

    const submitStep = () => {
        const nextState = produce(viewModel, () => {});
        logger.info('StepMilitaryStatusComponent submitting form');
        verificationService.submitStep(
            VerificationStepsEnum.collectMilitaryStatus,
            nextState,
            verificationService.previousVerificationResponse || verificationService.verificationResponse,
        );
    };

    if ((viewModel.status && previousViewModel && previousViewModel.status !== viewModel.status) ||
        (viewModel.status && verificationService.verificationResponse.currentStep === VerificationStepsEnum.collectMilitaryStatus)) {
        submitStep();
    }

    // Refs should be reset to ensure the field order isn't affected by the previous step
    resetRefs();

    return (
        <div id="sid-step-active-military-personal-info" className="sid-l-container">
            { failedInstantMatch ?
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.title" defaultMessage="Check Your Name & Birthdate" />
                    </div>

                    <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.subtitle"
                            defaultMessage="
                                Enter your first and last name on the form as it appears on your military document(s).
                                Keep in mind that may be your given name."
                        />
                    </div>
                </div>
            :
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.title" defaultMessage="Unlock this Military-Only Offer" />
                    </div>

                    <div className="sid-header__subtitle sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.subtitle"
                            defaultMessage="Verify you're a member of the armed forces." />
                    </div>
                    <div className="sid-header__how-verifying-works sid-l-horz-center">
                        <HowDoesVerifyingWork verificationService={verificationService} />
                        <RewardsRemainingComponent verificationService={verificationService} />
                    </div>
                </div>
            }

            <CountryComponentWrapper
                verificationService={verificationService}
                viewModel={viewModel}
                nextFocusField="status"
            />

            {
                availableStatuses ?
                <MilitaryStatus
                    value={{
                        value: (verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel).status,
                        label: MilitaryStatusDefaultMessagesEnum[(verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel).status],
                    }}
                    isErrored={!!fieldValidationErrors.status}
                    options={ availableStatuses }
                    onChange={
                        (status: FormSelectChoice) => {
                            updateActiveMilitaryViewModel('status', status ? (status.value as string) : '');
                            updateFieldValidationErrorsByFieldId('status', status ? (status.value as string) : '', verificationService);
                        }
                    }
                />
                : null
            }

            <BranchOfService
                autoFocus={viewModel.status && viewModel.organization.id === 0 ? true : false}
                isErrored={!!fieldValidationErrors.organization}
                onChange={
                    (choice: Organization) => {
                        updateViewModelOrganization(choice, verificationService);
                        updateFieldValidationErrorsByFieldId('organization', choice, verificationService);
                    }
                }
                value={(verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel).organization}
                verificationService={verificationService}
                organizations={ verificationService.orgList }
            />

            <div className="sid-names">
                <FirstName

                    value={viewModel.firstName}
                    isErrored={!!fieldValidationErrors.firstName}
                    onChange={
                        (newValue) => {
                            updateActiveMilitaryViewModel('firstName', newValue);
                            updateFieldValidationErrorsByFieldId('firstName', newValue, verificationService);
                        }
                    }
                />

                <LastName

                    value={viewModel.lastName}
                    isErrored={!!fieldValidationErrors.lastName}
                    onChange={
                        (newValue) => {
                            updateActiveMilitaryViewModel('lastName', newValue);
                            updateFieldValidationErrorsByFieldId('lastName', newValue, verificationService);
                        }
                    }
                />
            </div>

            <BirthDate
                isErrored={!!fieldValidationErrors.birthDate}
                errorId={fieldValidationErrors.birthDate}
                onChange={(newValue) => {
                    updateActiveMilitaryViewModel('birthDate', newValue);
                    updateFieldValidationErrorsByFieldId('birthDate', newValue, verificationService);
                }}
                value={viewModel.birthDate}
            />

            <Email
                value={viewModel.email}
                isErrored={!!fieldValidationErrors.email}
                explanation={
                    <FormattedHTMLMessage
                        id="emailExplanation"
                        defaultMessage="Personal email address is recommended"
                    />
                }
                onChange={
                    (newValue) => {
                        updateActiveMilitaryViewModel('email', newValue);
                        updateFieldValidationErrorsByFieldId('email', newValue, verificationService);
                    }
                }
                onKeyDown={event => handleEmailOnKeyDown(event)}
            />
            {
                verificationService.programTheme.isSmsNotifierConfigured || verificationService.programTheme.smsLoopEnabled ?
                    <PhoneNumber
                        isRequired={!!verificationService.programTheme.smsLoopEnabled}
                        value={viewModel.phoneNumber}
                        isErrored={!!fieldValidationErrors.phoneNumber}
                        onChange={
                            (newValue) => {
                                updateActiveMilitaryViewModel('phoneNumber', newValue);
                                updateFieldValidationErrorsByFieldId('phoneNumber', newValue, verificationService);
                            }
                        }
                    />
                    : null
            }
            <MarketConsentWrapper
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.marketConsentValue}
                onChange={(newValue) => {
                    updateActiveMilitaryViewModel('metadata', { ...verificationService.viewModel.metadata, marketConsentValue: newValue });
                    updateFieldValidationErrorsByFieldId('marketConsentValue', newValue, verificationService);
                }}
                viewModel={viewModel}
            />

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-btn-collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectActiveMilitaryPersonalInfo)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${!isFormFilled(viewModel, verificationService.formValidationOptions) ? 'sid-btn--disabled-like' : ''}`}
                        aria-labelledby="verify-status-text"
                        ref={button => setRef('submitButton', button)}
                    >
                        <span id="verify-status-text">
                            { failedInstantMatch ?
                                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
                            :
                                <FormattedHTMLMessage id="verifyMyMilitaryStatus" defaultMessage="Verify My Military Status" />
                            }
                        </span>
                    </button>
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepActiveMilitaryPersonalInfoComponent = injectIntl(StepActiveMilitaryPersonalInfo);

import React from 'react';
import { FormattedHTMLMessage, injectIntl, InjectedIntl } from 'react-intl';
import { VerificationService } from '../../lib/types/types';
import { getPrivacyPolicyUrl, getPrivacyPolicyCompanyName } from '../../lib/ProgramTheme/programThemeGetters';

interface PrivacyPolicyLinkProps {
    verificationService: VerificationService;
    includeCompanyName: boolean;
    intl?: InjectedIntl;
}

const PrivacyPolicyLink = ({ verificationService, includeCompanyName, intl }: PrivacyPolicyLinkProps) => {
    const { programTheme } = verificationService;
    const privacyPolicyHref = getPrivacyPolicyUrl(programTheme);
    const companyName = `${getPrivacyPolicyCompanyName(programTheme, intl)} `;

    return (
        <a className="sid-privacy-policy sid-link" href={privacyPolicyHref} target="_blank">
            {includeCompanyName ?
                <span>{companyName}</span>
                : null
            }
            <FormattedHTMLMessage id="privacyPolicy" defaultMessage="Privacy Policy" />
        </a>
    );
};

export const PrivacyPolicyLinkComponent = injectIntl(PrivacyPolicyLink);

import React from 'react';
import {
    MarketConsentOptions,
    VerificationService,
    ViewModel,
} from '../../../lib/types/types';
import { getMarketConsent } from '../../../lib/ProgramTheme/programThemeGetters';
import { OptInComponent } from '../../FormFields/OptIn/OptInComponent';

interface MarketConsentWrapperProps {
    verificationService: VerificationService;
    isErrored: boolean;
    onChange: (value: any) => void;
    viewModel: ViewModel;
}

const MarketConsentWrapperComponent = ({
    verificationService,
    isErrored,
    onChange,
    viewModel,
}: MarketConsentWrapperProps) => {
    const { programTheme } = verificationService;
    const marketConsentConfig: MarketConsentOptions = getMarketConsent(programTheme);

    return marketConsentConfig.enabled
        ? (
            <OptInComponent
                isErrored={isErrored}
                isRequired={marketConsentConfig.required}
                verificationService={verificationService}
                value={viewModel.metadata && viewModel.metadata.marketConsentValue || false}
                onChange={value => onChange(value)}
            />
        )
        : null;
};

// Do not use default export
export default MarketConsentWrapperComponent;

// Use this named export
export { MarketConsentWrapperComponent };

import { getOptions } from '../../options/options';
import { DatabaseId, Locale } from '../types/types';

export const getVerificationUrl = () => `${getOptions().restApi.serviceUrl}${getOptions().restApi.resources.verification}`;
export const getVerificationStatusUrl = (verificationId: DatabaseId) => `${getVerificationUrl()}${verificationId}`;
export const getNewVerificationRequestUrl = () => getVerificationUrl();
export const getProgramThemeUrl = (programId: DatabaseId, locale: Locale = null) => {
    const baseUrl = `${getOptions().restApi.serviceUrl}${getOptions().restApi.resources.program.base}${programId}/${getOptions().restApi.resources.program.theme}`;
    if (locale) {
        return `${baseUrl}?locale=${locale}`;
    }
    return `${baseUrl}`;
};
export const getAddSchoolRequestUrl = () => `${getOptions().restApi.serviceUrl}rest/v2/organization/addSchool/`;

export const getNewSmsCodeResendUrl = (verificationId: DatabaseId) => `${getOptions().restApi.serviceUrl}rest/v2/verification/${verificationId}/step/smsLoop/retry`;
export const getNewEmailCodeResendUrl = (verificationId: DatabaseId) => `${getOptions().restApi.serviceUrl}rest/v2/verification/${verificationId}/step/emailLoop/retry`;

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';

interface PassedProps {
    explanation?: string | JSX.Element;
}

export const MemberId = ({
    value,
    isErrored,
    onChange,
    explanation,
    intl,
    placeholder = '',
    isRequired = false,
}: FormFieldComponentProps & PassedProps) => {
    const getDefaultExplanation = () => (!isRequired
        ? (
            <FormattedHTMLMessage
                id="optional"
                defaultMessage="Optional"
            />
        )
        : '');
    return (
        <div className="sid-field sid-member-id">
            <div className="sid-l-space-top-md" />
            <label htmlFor="sid-member-id">
                <div className={`sid-field__label-with-explanation sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                    <div className={`sid-field__label${isRequired ? ' sid-field__label--required' : ''} `}>
                        <FormattedHTMLMessage
                            id="memberId"
                            defaultMessage="ID Number"
                        />
                    </div>
                    <div className="sid-field__label-explanation">
                        {
                            explanation || getDefaultExplanation()
                        }
                    </div>
                </div>
            </label>

            <InputTextComponent
                id="member-id"
                isErrored={isErrored}
                onChange={e => onChange(e.target.value)}
                placeholder={placeholder || intl.formatMessage({ id: 'memberIdPlaceholder', defaultMessage: 'ID Number*' })}
                refId="memberId"
                value={value}
            />

            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidMemberId"
                            defaultMessage="Invalid ID Number"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export const MemberIdComponent = injectIntl(MemberId);

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { debounce } from 'debounce';
import { fetchProgramOrganizations } from '../lib/ServerApi/OrganizationApiClient';
import { OrganizationListComponent } from './OrganizationList/OrganizationListComponent';
import { OrganizationSearchResp, PropertiesOf, DatabaseId, Locale } from '../lib/types/types';
import { logger } from '../lib/utils/logger/logger';
import { DEFAULT_LOCALE } from '../constants';

interface FetchOrganizationsProps {
    countryCode?: string;
    getItemProps: any;
    getMenuProps: any;
    highlightedIndex: any;
    itemToString: any;
    minimumSearchValueLength: number;
    onLoaded: any;
    programId: DatabaseId;
    searchValue: any;
    orgSearchUrl?: string;
    orgSearchTags?: string;
    locale?: Locale;
    urlAddSchoolForm?: string;
    openOrgSearchEnabled?: boolean;
}

interface FetchOrganizationState {
    isErrored: string;
    loading: boolean;
    organizations: OrganizationSearchResp;
}

type AddSchoolRequestRowProps = {
    programId: DatabaseId;
    locale: Locale;
    urlAddSchoolForm: string;
};

const AddSchoolRequestRow: React.FC<AddSchoolRequestRowProps> = ({ urlAddSchoolForm, programId, locale = DEFAULT_LOCALE }) => (
    <a
        className="sid-organization-list__item sid-organization-list__request-school sid-link"
        href={`${urlAddSchoolForm}?pid=${programId}&locale=${locale}`}
        target="_blank"
    >
        <FormattedHTMLMessage
            id="requestSchool"
            defaultMessage="Don't see your school? Request to have it added"
        />
    </a>
);

class FetchOrganizations extends React.Component<FetchOrganizationsProps, FetchOrganizationState> {
    constructor(props) {
        super(props);
        this.state = {
            isErrored: null,
            loading: false,
            organizations: null,
        };
    }

    static initialState: FetchOrganizationState = { isErrored: null, loading: false, organizations: null };

    mounted = false;

    reset(overrides: PropertiesOf<FetchOrganizationState>) {
        this.setState({ ...FetchOrganizations.initialState, ...overrides });
    }

    fetch = async () => {
        const { orgSearchUrl, searchValue, countryCode, orgSearchTags } = this.props;
        if (!this.mounted) {
            return;
        }
        await fetchProgramOrganizations(orgSearchUrl, searchValue, countryCode, orgSearchTags).then(
            (organizations: OrganizationSearchResp) => {
                if (organizations) {
                    this.props.onLoaded(organizations);
                    this.setState({ organizations, loading: false });
                }
            },
        );
    }

    // Prevent searching while user is actively typing
    debouncedFetch = debounce(this.fetch, 200);

    componentDidMount() {
        const { minimumSearchValueLength, searchValue } = this.props;
        this.mounted = true;

        if (searchValue.length >= minimumSearchValueLength) {
            this.reset({ loading: true });
            this.debouncedFetch();
        } else {
            logger.info(`No organizations fetched. Search term "${searchValue}" is less than ${minimumSearchValueLength} characters.`);
        }
    }

    componentDidUpdate(prevProps) {
        const { minimumSearchValueLength, searchValue } = this.props;

        if (prevProps.searchValue !== searchValue) {
            if (searchValue.length >= minimumSearchValueLength) {
                this.reset({ loading: true });
                this.debouncedFetch();
            } else {
                this.reset({ organizations: null, loading: false });
                logger.info(`No organizations fetched. Search term "${searchValue}" is less than ${minimumSearchValueLength} characters.`);
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { isErrored, loading, organizations } = this.state;
        const {
            getItemProps, getMenuProps, highlightedIndex, itemToString, searchValue, programId, locale, urlAddSchoolForm, openOrgSearchEnabled,
        } = this.props;

        if (loading) {
            return (
                <div className="sid-organization-list__menu">
                    <div className="sid-organization-list__message">
                        {/* TODO: Define correct message ID from backend (backend need to send this message?) */}
                        <FormattedHTMLMessage
                            id="loading"
                            defaultMessage="Loading..."
                        />
                    </div>
                </div>
            );
        }
        if (isErrored) {
            return (
                <div className="sid-organization-list__menu">
                    <div className="sid-organization-list__message">
                        {/* TODO: Define correct message ID from backend (backend need to send this message?) */}
                        <FormattedHTMLMessage
                            id="errorId.unknownError"
                            defaultMessage="An unknown error occurred"
                        />
                    </div>
                </div>
            );
        }

        if (organizations && organizations.length > 0) {
            return (
                <OrganizationListComponent
                    getItemProps={getItemProps}
                    getMenuProps={getMenuProps}
                    highlightedIndex={highlightedIndex}
                    itemToString={itemToString}
                    organizations={organizations}
                >
                    {searchValue && !openOrgSearchEnabled && urlAddSchoolForm && (!organizations || organizations.length <= 5) && (
                        <AddSchoolRequestRow urlAddSchoolForm={urlAddSchoolForm} programId={programId} locale={locale} />
                    )}
                </OrganizationListComponent>
            );
        }

        if (searchValue && !openOrgSearchEnabled && urlAddSchoolForm && (!organizations || organizations.length === 0)) {
            return (
                <div className="sid-organization-list__menu">
                    <AddSchoolRequestRow urlAddSchoolForm={urlAddSchoolForm} programId={programId} locale={locale} />
                </div>
            );
        }

        return null;
    }
}

export const FetchOrganizationsComponent = FetchOrganizations;

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { requestOrganizationConstants } from '../../constants';
import { RequestOrganizationService } from '../../lib/types/types';
import { getLogoUrl } from '../../lib/ProgramTheme/programThemeGetters';

interface RequestOrganizationSuccessComponentProps {
    requestOrganizationService: RequestOrganizationService;
}

export const RequestOrganizationSuccessComponent = ({ requestOrganizationService }: RequestOrganizationSuccessComponentProps) => {
    const { programTheme } = requestOrganizationService;

    return (
        <div className="sid-l-container--extra-wide sid-l-space-btm-lg sid-l-container">
            <div className="sid-logo sid-l-horz-center sid-l-space-top-lg">
                <img
                    className="sid-logo__img"
                    alt="Logo"
                    src={programTheme && getLogoUrl(programTheme) ? getLogoUrl(programTheme) : requestOrganizationConstants.SHEERID_LOGO_URL}
                />
            </div>
            <div className="sid-l-horz-center">
                <div className="sid-logo sid-l-space-top-lg">
                    <img
                        className="sid-logo__icon"
                        alt="Success"
                        src="https://s3.amazonaws.com/com.sheerid.resources/common/images/requestOrganization/circle-check.svg"
                    />
                </div>
                <div className="sid-title">
                    <FormattedHTMLMessage
                        id="step.success.title"
                        defaultMessage="Thank you for submitting your school request"
                    />
                </div>
                <div className="sid-soft-font-color">
                    <FormattedHTMLMessage
                        id="step.success.description"
                        defaultMessage="As a reminder, each request is evaluated by SheerID against the requirements set by this offer. There is no guarantee that your school will be added. The evaluation process takes up to 7 business days."
                    />
                </div>
            </div>
        </div>
    );
};

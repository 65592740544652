import { ReduxState, PersonalInfoViewModel, Metadata, VerificationResponse, VerificationServiceAction } from '../../lib/types/types';
import { getFieldValidationErrorsEmpty } from '../../lib/types/empties';
import { VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { setMetadata, getMetadata } from '../metadata/metadata';
import { deepClone } from '../utils/objects';
import { recordViewModelChange, recordVerificationResponse, setDimension, isTestValues } from '../GoogleAnalytics/ga';

/**
 * The initial state of the verification service.
 */
const initialState: ReduxState = Object.freeze({
    programId: undefined,
    programTheme: undefined,
    isLoading: true,
    isErrored: false,
    viewModel: undefined,
    previousViewModel: undefined,
    previousVerificationResponse: undefined,
    verificationResponse: undefined,
    fieldValidationErrors: getFieldValidationErrorsEmpty(),
    messages: undefined,
    orgList: undefined,
    formValidationOptions: {},
});

const getInitialState = () => deepClone(initialState);

/**
 * @description Most of the state for the VerificationService
 * @example as a user fills-in the HTML form, we can track the data entered here in the view model.
 * @param state
 * @param action
 */
export const verificationServiceReducer = (state: ReduxState = getInitialState(), action: VerificationServiceAction): ReduxState => {
    switch (action.type) {
        case 'PROGRAM_ID':
            setDimension('programId', action.programId);
            return {
                ...state,
                programId: action.programId,
            };
        case 'VERIFICATION_RESPONSE':
            let previousVerificationResponse: VerificationResponse;
            if (state.verificationResponse &&
                (state.verificationResponse.currentStep === VerificationStepsEnum.collectMilitaryStatus ||
                    state.verificationResponse.currentStep === VerificationStepsEnum.sso ||
                    state.verificationResponse.currentStep === VerificationStepsEnum.docUpload)) {
                previousVerificationResponse = state.verificationResponse;
            } else if (state.previousVerificationResponse) {
                previousVerificationResponse = state.previousVerificationResponse;
            } else {
                previousVerificationResponse = state.verificationResponse;
            }

            recordVerificationResponse(action.verificationResponse);
            return {
                ...state,
                previousVerificationResponse,
                // When we get a verificationResponse, hold on to the last viewModel
                // before re-render, in case it needs to be referenced in the new step:
                previousViewModel: state.viewModel,
                verificationResponse: action.verificationResponse,
            };
        case 'SET_MESSAGES':
            return {
                ...state,
                messages: action.messages,
            };
        case 'FIELD_VALIDATION_ERRORS':
            return {
                ...state,
                fieldValidationErrors: action.fieldValidationErrors,
            };
        case 'VIEW_MODEL':
            const newState: ReduxState = { ...state };

            if (action.viewModel) {
                // Allow action.viewModel to be a sparse object. Bring along all previous viewModel
                newState.viewModel = { ...action.viewModel };
            }

            if (action.viewModel && action.viewModel.metadata) {
                setMetadata(action.viewModel.metadata);
                const metadata: Metadata = getMetadata();
                (newState.viewModel as PersonalInfoViewModel).metadata = metadata;
            }

            recordViewModelChange(state, newState);
            return newState;
        case 'PRE_LOAD_ORGS':
            return {
                ...state,
                orgList: action.orgList,
            };
        case 'PREVIOUS_VIEW_MODEL':
            return {
                ...state,
                previousViewModel: action.previousViewModel,
            };
        case 'PROGRAM_THEME':
            setDimension('isTest', action.programTheme.isTestMode ? isTestValues.TRUE : isTestValues.FALSE);
            return {
                ...state,
                programTheme: action.programTheme,
            };
        case 'FORM_VALIDATION_OPTIONS':
            return {
                ...state,
                formValidationOptions: {
                    maxAge: state.verificationResponse.maxAge,
                    minAge: state.verificationResponse.minAge,
                    smsLoopEnabled: state.programTheme.smsLoopEnabled,
                    currentStep: state.verificationResponse.currentStep,
                    viewModel: state.viewModel,
                },
            };
        case 'IS_LOADING':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case 'IS_ERRORED':
            return {
                ...state,
                isErrored: action.isErrored,
            };
        case 'RESET_STATE':
            return {
                ...deepClone(getInitialState()),
            };

        case 'FORCE_UPDATE':
            return {
                ...deepClone(state),
            };
        default:
            return state;
    }
};

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormSelectChoice, InputSelectComponentProps } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';

export const StateSelect = ({
    value,
    isErrored,
    options,
    onChange,
    onKeyDown,
    intl,
    placeholder = '',
    label = '',
    isRequired = false,
}: InputSelectComponentProps) => (
        <div className="sid-field sid-state">
            <div className="sid-l-space-top-md" />
            <label htmlFor="sid-state">
                <div className={`sid-field__label sid-state__label sid-l-space-btm-sm${isRequired ? ' sid-field__label--required' : ''}`}>
                    {
                        label || <FormattedHTMLMessage
                            id="state"
                            defaultMessage="State where you live"
                        />
                    }
                </div>
            </label>
            <InputSelectComponent
                fieldId="state"
                inputId="sid-state"
                isErrored={isErrored}
                options={options}
                onChange={(state: FormSelectChoice) => onChange(state)}
                onKeyDown={onKeyDown}
                placeholder={placeholder || intl.formatMessage({ id: 'statePlaceholder', defaultMessage: isRequired ? 'State*' : 'State' })}
                value={value ? {
                    value: value.value,
                    label: intl.formatMessage({ id: `states.${value.value}`, defaultMessage: value.label || value.value }),
                } : undefined}
            />
            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidState"
                            defaultMessage="Invalid state"
                        />
                    </div>
                ) : null
            }
        </div>
    );

export const StateSelectComponent = injectIntl(StateSelect);

import React from 'react';
import { FormattedHTMLMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { FormSelectChoice, InputSelectComponentProps } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';
import { SortByLabel } from '../../../lib/utils/structures/FormSelectChoice';

export const MilitaryStatus = ({
    value, isErrored, options, onChange, intl, placeholder = '',
}: InputSelectComponentProps & InjectedIntlProps) => (
    <div className="sid-field sid-military-status">
        <div className="sid-l-space-top-md" />
        <label htmlFor="sid-military-status">
            <div className="sid-field__label sid-field__label--required sid-military-status__label sid-l-space-btm-sm">
                <FormattedHTMLMessage
                    id="status"
                    defaultMessage="Status"
                />
            </div>
        </label>

        <InputSelectComponent
            fieldId="status"
            inputId="sid-military-status"
            isErrored={isErrored}
            options={SortByLabel(options)}
            onChange={(status: FormSelectChoice) => onChange(status)}
            value={value}
            placeholder={placeholder || intl.formatMessage({ id: 'statusPlaceholder', defaultMessage: 'Status*' })}
        />

        {
            isErrored ? (
                <div className="sid-field-error">
                    <FormattedHTMLMessage
                        id="errorId.invalidStatus"
                        defaultMessage="Invalid military status"
                    />
                </div>
            ) : null
        }
    </div>
);

export const MilitaryStatusComponent = injectIntl(MilitaryStatus);

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Downshift from 'downshift';

import { FormSelectChoice } from '../../../lib/types/types';
import { SelectListComponent } from './SelectList/SelectListComponent';
import { SelectButtonComponent } from './SelectButton/SelectButtonComponent';


interface SelectProps {
    className?: string;
    hideDropDownButton?: boolean;
    isErrored?: boolean;
    onChange: any;
    options: FormSelectChoice[];
    overrideInputClassName?: string;
    placeholder?: string;
    value?: FormSelectChoice;
}

const Select = ({
    className, hideDropDownButton, isErrored, options, onChange, overrideInputClassName, placeholder, value,
}: SelectProps) => {
    const itemToString = item => (item ? item.label : '');

    return (
        <Downshift
            onChange={onChange}
            itemToString={itemToString}
            initialSelectedItem={value && value.value && value.label ? value : undefined}
            defaultHighlightedIndex={0}
        >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                getToggleButtonProps,
                highlightedIndex,
                isOpen,
                openMenu,
            }) => (
                <div className={`sid-select-list ${className}`}>
                    <input
                        {...getInputProps({ onFocus: openMenu })}
                        {...getToggleButtonProps}
                        className={`
                        sid-l-full-width
                        ${overrideInputClassName || 'sid-select-input'}
                        ${isErrored ? 'sid-select-input--error' : ''}
                    `}
                        placeholder={placeholder}
                        readOnly
                    />

                    {!hideDropDownButton
                        ? (
                            <SelectButtonComponent
                                getToggleButtonProps={getToggleButtonProps}
                                isOpen={isOpen}
                            />
                        )
                        : null}

                    {isOpen
                        ? (
                            <div>
                                {options.length > 0
                                    ? (
                                        <SelectListComponent
                                            highlightedIndex={highlightedIndex}
                                            itemToString={itemToString}
                                            options={options}
                                            getItemProps={getItemProps}
                                            getMenuProps={getMenuProps}
                                        />
                                    )
                                    : (
                                        <div className="sid-select-list__menu" {...getMenuProps()}>
                                            <div className="sid-select-list__message">
                                                <FormattedHTMLMessage
                                                    id="noOptions"
                                                    defaultMessage="No Options"
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                        : null}
                </div>
            )}
        </Downshift>
    );
};

Select.defaultProps = {
    className: undefined,
    hideDropDownButton: false,
    isErrored: false,
    overrideInputClassName: undefined,
    placeholder: '',
    value: undefined,
};

export const SelectComponent = Select;

/**
 * TODO - preamble
 */
import './main.scss';

import './polyfills/polyfills';

import { SheerIdJsApi } from './lib/types/types';

import { VerificationFormEs5 } from './es5Bindings/VerificationFormEs5';
import { BirthdateField } from './es5Bindings/BirthdateField';
import { StudentTypeaheadField } from './es5Bindings/StudentTypeaheadField';

// Import as much as possible from es6, to recycle that interface and ensure it stays functional
import {
    addHook,
    conversion,
    setViewModel,
    resetViewModel,
    setOptions,
    resetOptions,
    setMetadata,
    getMetadata,
    resetMetadata,
    overrideComponent,
    resetOverriddenComponents,
    loadInModal,
    loadInlineIframe,
    postVerificationSizeUpdates,
    getSafe,
} from './es6';

// Modify window interface to allow the SheerID object.
declare global {
    interface Window {
        sheerId: SheerIdJsApi;
        NREUM: any; // New Relic
        ga: any; // Google Analytics
        sheerIdReqV: string; // src string of the jslib <script> tag, e.g. https://cdn.jsdelivr.net/npm/@sheerid/jslib-nightly@alpha/sheerid.js
        sheerIdPubV: string; // published version. The full version name baked into the jslib artifact, even if e.g. @latest was the src address
    }
}

// If you just use the outputted bundle, and don't include the es6 source code in your project/build,
// this SheerID object is the API you'd interact with.
// See /examples/main.html
// Implements the SheerIdJsApi interface
// window.sheerid interface:
const thisJslib: SheerIdJsApi = {
    setViewModel,
    resetViewModel,
    setOptions,
    resetOptions,
    setMetadata,
    getMetadata,
    resetMetadata,
    StudentTypeaheadField,
    BirthdateField,
    conversion,
    overrideComponent,
    resetOverriddenComponents,
    loadInModal,
    loadInlineIframe,
    postVerificationSizeUpdates,
    addHook,
    hooks: undefined,
    VerificationForm: VerificationFormEs5,
};

window.sheerId = { ...(window.sheerId || {}), ...thisJslib, hooks: getSafe(() => window.sheerId.hooks, []) };


// TODO - remove. Use es6.tsx instead
export { setOptions, resetOptions } from './options/options';
export { VerificationForm } from './components/VerificationForm/VerificationFormComponent';
export { overrideComponent, resetOverriddenComponents } from './lib/componentOverride/componentOverride';
// END TODO - remove. Use es6.tsx instead

// NOTE: The following lines will make sure the different chunks (e.g. languages) are loaded from
// the same origin as the main.js file. This is only valid and necessary for the "es5" version of the library
// @ts-ignore
const scriptBasePath = document.currentScript.src.substr(0, document.currentScript.src.lastIndexOf('/'));
// @ts-ignore
__webpack_public_path__ = `${scriptBasePath}/${__webpack_public_path__}`;

(() => {
    try {
        // @ts-ignore
        window.sheerIdReqV = /jslib(\-nightly)?\@[a-zA-Z0-9\.\-]+/ig.exec(document.currentScript.src)[0].split('@')[1].trim();
    } catch (e) {
        console.warn('Unable to determine requested library version', e);
    }
})();

import React from 'react';
import { FormattedHTMLMessage, injectIntl, InjectedIntl } from 'react-intl';
import { produce } from 'immer';

import { VerificationStepsEnum, FieldIdEnum } from '../../lib/types/runtimeTypes';
import { setRef } from '../../lib/refs/refs';
import {
    VerificationService,
    FieldValidationErrors,
    ExtendedFieldValidationErrors,
    SocialSecurityViewModel,
} from '../../lib/types/types';
import {
    submitForm,
    updateFieldValidationErrorsByFieldId,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { SocialSecurityNumber } from '../FormFields/SSN/SSN';
import { SsnChoice } from './SsnChoice';

interface StepSocialSecurityNumberProps {
    intl: InjectedIntl;
    verificationService: VerificationService;
}

const Step = ({ intl, verificationService }: StepSocialSecurityNumberProps) => {
    const [showChoice, setShowChoice] = React.useState(true);
    const viewModel = verificationService.viewModel as SocialSecurityViewModel;
    const fieldValidationErrors: FieldValidationErrors & ExtendedFieldValidationErrors = verificationService.fieldValidationErrors;
    const doCancelStep = () => verificationService.submitStep(
        VerificationStepsEnum.cancelSocialSecurityNumber,
        viewModel,
        verificationService.verificationResponse,
    );

    const updateSSNViewModel = (key: keyof SocialSecurityViewModel, value: any) => {
        const nextState: SocialSecurityViewModel = produce(viewModel, (draft: SocialSecurityViewModel) => {
            (draft[key] as any) = value;
        });
        verificationService.updateViewModel(nextState);
    };

    const isSubmittable = viewModel.socialSecurityNumber && viewModel.socialSecurityNumber.toString().length === 9;

    if (showChoice) {
        return <SsnChoice
            useSsn={() => setShowChoice(false)}
            useDocUpload={doCancelStep}
            verificationService={verificationService}
        />;
    }

    return (
        <div id="sid-step-social-security" className="sid-l-container sid-l-horz-center">
            <div className="sid-header">
                <div className="sid-l-horz-center">
                    <LogoComponent verificationService={verificationService} />
                </div>
                <div className="sid-header__title sid-l-horz-center">
                    <FormattedHTMLMessage id="step.collectSocialSecurityNumber.title" defaultMessage="Enter your social security number" />
                </div>

                <div className="sid-ssn-wrapper">
                    <SocialSecurityNumber
                        value={viewModel.socialSecurityNumber}
                        onChange={(value: number) => {
                            updateSSNViewModel('socialSecurityNumber', value);
                            updateFieldValidationErrorsByFieldId('socialSecurityNumber', value, verificationService);
                        }}
                        isErrored={!!fieldValidationErrors[FieldIdEnum.socialSecurityNumber]}
                        placeholder="000-00-0000"
                    />
                </div>
            </div>

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-wrapper__collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectSocialSecurityNumber)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${ isSubmittable ? '' : 'sid-btn--disabled-like'}`}
                        aria-label="submit"
                        ref={button => setRef('submitButton', button)}
                    >
                        <FormattedHTMLMessage id="proceed" defaultMessage="Proceed" />
                    </button>
                </div>
            </div>

            <div className="sid-form-region sid-l-space-top-md" onClick={doCancelStep}>
                <div className="sid-h-link-like">
                    <FormattedHTMLMessage id="step.collectSocialSecurityNumber.uploadInstead" defaultMessage="Upload a document instead" />
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepSocialSecurityNumber = injectIntl(Step);

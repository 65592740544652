export const fadeInElements = (elements : HTMLElement[]) => {
    elements.forEach((element : HTMLElement) => {
        element.classList.add('fade-in');
    });
};

export const removeElement = (element : HTMLElement, transitionTimeout: number) => {
    element.classList.remove('fade-in');
    window.setTimeout(() => {
        document.body.style.overflow = 'auto';
        element.parentNode.removeChild(element);
    }, transitionTimeout);
};

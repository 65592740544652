import { logger } from '../utils/logger/logger';
import ResponseTimeLogger from '../utils/responseTimeLogger/ResponseTimeLogger';
import { getOptions } from '../../options/options';
import { NetworkErrorId } from '../types/types';

const defaultJsonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const defaultFileUploadHeaders = {
    Accept: 'application/json',
};

export const fetchWithTimeout = (url: string, options = {}) => {
    const timeout = getOptions().httpRequestTimeout;

    return new Promise<Response>((resolve, reject) => {
        const timeoutTimer = setTimeout(() => {
            const networkErrorId: NetworkErrorId = 'requestTimeout';

            reject(new Error(networkErrorId));
        }, timeout);

        return fetch(url, options).then(resolve, (error) => {
            logger.error(`Failed to fetch ${url}`, error);
            const networkErrorId: NetworkErrorId = 'failedToFetch';

            reject(new Error(networkErrorId));
        })
        .finally(() => {
            clearTimeout(timeoutTimer);
        });
    });
};

export const PostJson = async (url: string, body: Object, headers = defaultJsonHeaders): Promise<Object> => {
    const timeLog = new ResponseTimeLogger(url);
    const response = await fetchWithTimeout(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
    });
    return await processResponse(response, timeLog);
};

export const DeleteJson = async (url: string, headers = defaultJsonHeaders): Promise<Object> => {
    const timeLog = new ResponseTimeLogger(url);
    const response = await fetchWithTimeout(url, {
        headers,
        method: 'DELETE',
    });
    return await processResponse(response, timeLog);
};

export const GetJson = async (url: string, headers = defaultJsonHeaders): Promise<Object> => {
    const timeLog = new ResponseTimeLogger(url);
    const response = await fetchWithTimeout(url, {
        headers,
        method: 'GET',
    });
    return await processResponse(response, timeLog);
};

export const GetResponse = async (url: string, headers = defaultJsonHeaders): Promise<Object> => await fetchWithTimeout(url, {
    headers,
    method: 'GET',
});

export const createFormData = (files: File[]) => {
    const formData = new FormData();
    files.forEach((file, index) => {
        if (file) {
            formData.append(`file${index}`, file);
        }
    });
    return formData;
};

export const PostFiles = async (url: string, files: File[], headers = defaultFileUploadHeaders): Promise<Object> => {
    const timeLog = new ResponseTimeLogger(url);
    logger.info(`PostFiles posting to ${url}`, files);

    const formData = createFormData(files);
    const response = await fetch(url, {
        headers,
        method: 'POST',
        body: formData,
    });
    return await processResponse(response, timeLog);
};

const processResponse = async (response: Response, timeLog: ResponseTimeLogger): Promise<Object> => {
    let responseData: Promise<Object>;
    try {
        responseData = await response.json();
    } catch (error) {
        // Handle 204 response
        responseData = Promise.resolve({});
    } finally {
        timeLog.logNow();
        logger.info('Response received', responseData);
    }
    return responseData;
};

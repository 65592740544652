import { DatabaseId } from '../../types/types';
import { logger } from '../logger/logger';

export const ensureTrailingSlash = (url: string) => url.replace(/\/?$/, '/');

export const getQueryParamsFromUrl = (url?: string) => {
    const newUrl = new URL(url || window.location.toString());
    return new URLSearchParams(newUrl.search.slice(1));
};

export const getVerificationIdFromQueryString = (queryString: string): DatabaseId => {
    const verificationIdQueryParameter: string = 'verificationId';
    const queryStringParameters: URLSearchParams = new URLSearchParams(queryString);
    const verificationId: DatabaseId = queryStringParameters.get(verificationIdQueryParameter);
    if (typeof verificationId === 'string' && verificationId.length === 0) {
        return null;
    }
    return verificationId;
};

export const getTrackingIdFromQueryString = (queryString: string): string => {
    const trackingIdQueryParameter: string = 'trackingId';
    const queryStringParameters: URLSearchParams = new URLSearchParams(queryString);
    const trackingId: DatabaseId = queryStringParameters.get(trackingIdQueryParameter);
    if (typeof trackingId === 'string' && trackingId.length === 0) {
        return null;
    }
    return trackingId;
};

export const getDomainFromUrl = (url: string) => {
    let domain = url;
    try {
        domain = new URL(url).hostname;
    } catch (e) {
    }
    return domain.replace(/(www\.)?/, '');
};


export const redirectTo = (path: string) => {
    document.location.href = path;
};

/**
 * @private
 * @description for <script> elements on this html page, if one comes from
 * *cdn.jsdelivr.net/npm/@sheerid/jslib*, extract and the entire path without a filename.
 * Fixes BAL-664
 */
export const getScriptInitiatorPath = (scriptElements: any): string => {
    for (const scriptElement of scriptElements) {
        const pattern = /cdn\.jsdelivr\.net\/npm\/\@sheerid\/jslib.*sheerid\.js/i;
        const scriptSrc = scriptElement.getAttribute('src');
        if (pattern.test(scriptSrc)) {
            return removeFilenameFromUrl(scriptSrc);
        }
    }

    return undefined;
};

/**
 * @description Return the URL without the filename at the end.
 * @private
 */
export const removeFilenameFromUrl = (url: string): string => `${url.substring(0, url.lastIndexOf('/'))}/`;

/**
 * @private
 */
export const getScripts = (): any => document.getElementsByTagName('script');

/**
 * @description Configure webpack so child scripts created by code-splitting have the same url/path as the primary application script (sheerid.js)
 */
export const initCodeSplitBaseUrl = () => {
    const scriptInitiatorPath = getScriptInitiatorPath(getScripts());

    if (scriptInitiatorPath) {
        // @ts-ignore
        __webpack_public_path__ = scriptInitiatorPath;
        logger.info(`__webpack_public_path__ set to ${scriptInitiatorPath}`);
    } else {
        logger.warn(`__webpack_public_path__ unable to be set "${scriptInitiatorPath}"`);
    }
};

import React from 'react';
import { injectIntl, InjectedIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import {
    VerificationService,
    ErrorId,
    ErrorResponse,
} from '../lib/types/types';
import { PoweredByComponent } from './PoweredBy/PoweredByComponent';
import { TryAgainButtonComponent } from './TryAgainButtonComponent';
import { LogoComponent } from './LogoComponent/LogoComponent';
import { getLogoUrl } from '../lib/ProgramTheme/programThemeGetters';
import { requestOrganizationConstants as constants } from '../constants';
import { getLocaleSafely } from '../lib/intl/intl';

interface StepErrorProps {
    verificationService: VerificationService;
    errorId?: ErrorId;
    intl?: InjectedIntl;
}

const StepError = ({ verificationService, errorId }: StepErrorProps) => {
    const verificationResponse = verificationService.verificationResponse as ErrorResponse;
    const hasLogo = getLogoUrl(verificationService.programTheme) ? true : false;
    const limitExceededError = verificationResponse.errorIds.includes('verificationLimitExceeded');
    const errorTitle: FormattedMessage.Props =
        limitExceededError ?
        {
            id: 'limitExceededError',
            defaultMessage: 'Verification Limit Exceeded',
        } :
        {
            id: 'error',
            defaultMessage: 'Error',
        };
    const logoContainerClass = limitExceededError ? 'sid-l-horz-center sid-l-space-top-xl' : 'sid-l-horz-center';

    return (
        <div id="sid-step-error" className="sid-l-container">
            <div className="sid-header sid-l-horz-center">
                <div className={logoContainerClass}>
                    { hasLogo ?
                        <LogoComponent verificationService={verificationService} />
                        :
                        <img
                            className="sid-l-lead-image"
                            alt="design element"
                            src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/circle-exclamation.svg"
                        />
                    }
                </div>
                <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
                    <FormattedHTMLMessage {...errorTitle} />
                </div>
                <div className="sid-header__subtitle sid-l-horz-center sid-l-space-top-md">
                    <div className="sid-error-msg sid-l-horz-center">
                        {errorId !== undefined
                            ? <FormattedHTMLMessage id={`errorId.${errorId}`} defaultMessage="Unknown Error" />
                            : null
                        }
                    </div>
                    <div className="sid-error-msg sid-l-horz-center">
                        {
                            verificationResponse.errorIds ?
                            (
                                <p>
                                    {
                                        verificationResponse.errorIds.map(
                                            errId =>
                                            <FormattedHTMLMessage id={`errorId.${errId}`} defaultMessage={errId} values={{
                                                feedbackUrl: constants.FEEDBACK_FORM_URL,
                                                programId: verificationService.programId || '',
                                                locale: getLocaleSafely(verificationService.viewModel, verificationResponse).replace('-', '_'),
                                            }} />,
                                        )
                                    }
                                </p>
                            )
                            : null
                        }
                    </div>
                    {!limitExceededError && (
                        <div className="sid-l-space-top-lg sid-l-half-width sid-l-horz-center">
                            <TryAgainButtonComponent
                                verificationService={verificationService}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="sid-footer sid-l-horz-center sid-l-space-top-xl sid-h-small-text">
                <PoweredByComponent verificationService={verificationService} isCentered />
            </div>
        </div>
    );
};

export const StepErrorComponent = injectIntl(StepError);

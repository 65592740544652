import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Organization } from '../../lib/types/types';
import { OrganizationResultComponent as OrganizationResult } from '../../components/RequestOrganization/OrganizationResultComponent';
import { requestOrganizationConstants } from '../../constants';

interface RequestOrganizationSearchResultComponentProps {
    eligibleOrgs: Organization[];
    ineligibleOrgs: Organization[];
}

export const RequestOrganizationSearchResultComponent = ({ eligibleOrgs, ineligibleOrgs }: RequestOrganizationSearchResultComponentProps) => {
    const hasMaxResults = () => (eligibleOrgs.length + ineligibleOrgs.length >= requestOrganizationConstants.MAX_RESULT_SIZE);

    return (
        <div className="sid-l-space-top-md">
            {
                hasMaxResults()
                    ? (
                        <div className="sid-header__subtitle--error">
                            <FormattedHTMLMessage
                                id="errorId.tooManyResults"
                                defaultMessage="Your search returned too many results, please refine your search and try again"
                            />
                        </div>
                    )
                    : null
            }
            <div>
                <FormattedHTMLMessage
                    id="eligibleOrgs"
                    defaultMessage="Eligible schools. Click the icon to copy name then return to the verification form:"
                />
            </div>
            <div className="sid-l-space-top-sm">
                {
                    eligibleOrgs.length > 0
                        ? eligibleOrgs.map(organization => <OrganizationResult key={organization.id} organization={organization} isEligible />)
                        : (
                            <div>
                                <FormattedHTMLMessage
                                    id="none"
                                    defaultMessage="None"
                                />
                            </div>
                        )
                }
            </div>
            <div className="sid-l-space-top-md">
                <FormattedHTMLMessage
                    id="ineligibleOrgs"
                    defaultMessage="Ineligible Schools. We are sorry, students at these schools are not eligible for this offer:"
                />
            </div>
            <div className="sid-l-space-top-sm">
            {
                ineligibleOrgs.length > 0 ?
                    ineligibleOrgs.map(organization => <OrganizationResult key={organization.id} organization={organization} isEligible={false} />)
                : <div>
                    <FormattedHTMLMessage
                        id="none"
                        defaultMessage="None"
                    />
                </div>
            }
            </div>
        </div>
    );
};

import { logger } from '../utils/logger/logger';
import { VerificationStep, VerificationResponse, MockStep, SubSegment, ErrorId } from '../types/types';
import { MockStepsEnum, VerificationStepsEnum } from '../types/runtimeTypes';
import { getOptions } from '../../options/options';
import { QUERY_STRING_STEP_OVERRIDE, QUERY_STRING_SUBSEGMENT_OVERRIDE, QUERY_STRING_ERRORID_OVERRIDE } from '../../constants';

import { allMockedResponses } from '../../serverMocks/mocks';

// To load a specific step directly, visit e.g. localhost:1234/?step=success
export const getRouteOverride = (): MockStep | undefined => {
    try {
        const parsed: URLSearchParams = new URLSearchParams(window.location.search);

        if (parsed.get(QUERY_STRING_STEP_OVERRIDE)) {
            return parsed.get(QUERY_STRING_STEP_OVERRIDE) as VerificationStep;
        }
        if (getOptions().mockStep) {
            return getOptions().mockStep;
        }
    } catch (e) {
        logger.error(e, 'getRouteOverride');
    }
};

// When loading a specific step, specify a subsegment
export const getSubsegmentOverride = (): SubSegment | undefined => {
    try {
        const parsed: URLSearchParams = new URLSearchParams(window.location.search);

        if (parsed.get(QUERY_STRING_SUBSEGMENT_OVERRIDE)) {
            return parsed.get(QUERY_STRING_SUBSEGMENT_OVERRIDE) as SubSegment;
        }
        if (getOptions().mockSubSegment) {
            return getOptions().mockSubSegment;
        }
    } catch (e) {
        logger.error(e, 'getSubsegmentOverride');
    }
};

// When loading the error step, specify an errorId
export const getErrorIdOverride = (): ErrorId | undefined => {
    try {
        const parsed: URLSearchParams = new URLSearchParams(window.location.search);

        if (parsed.get(QUERY_STRING_ERRORID_OVERRIDE)) {
            return parsed.get(QUERY_STRING_ERRORID_OVERRIDE) as ErrorId;
        }
        if (getOptions().mockErrorId) {
            return getOptions().mockErrorId;
        }
    } catch (e) {
        logger.error(e, 'getErrorIdOverride');
    }
};

// Special case. The loading screen is not a step, so mocking it is a little different.
export const isMockingLoading = (): boolean => {
    try {
        const parsed: URLSearchParams = new URLSearchParams(window.location.search);
        return (parsed.get(QUERY_STRING_STEP_OVERRIDE) === MockStepsEnum.loading || getOptions().mockStep === MockStepsEnum.loading);
    } catch (e) {
        logger.error(e, 'isMockingLoading');
        return false;
    }
};

/**
 * Based on page request query params, return a fake response to allow direct access to any step.
 *
 * @param overriddenStep
 * @param serverResponse The actual serverResponse to acquire certain pieces of info from.
 */
export const getOverriddenMock = async (overriddenStep: MockStep, serverResponse?: VerificationResponse): Promise<VerificationResponse> => {
    let mockResponse = allMockedResponses[overriddenStep];
    if (overriddenStep && mockResponse) {
        const errorMock = getErrorIdOverride();
        logger.info(`getOverriddenMock: mocking ${overriddenStep} response`);
        if (overriddenStep === VerificationStepsEnum.error && errorMock) {
            mockResponse.errorIds = [errorMock];
        }
    } else {
        mockResponse = null;
        logger.info(`
            getOverriddenMock: not mocking response.
            You may mock a step response by adding things like ?mockStep=success to your route.
            ${overriddenStep}
        `);
    }

    if (mockResponse !== null && serverResponse) {
        await serverResponse;
        mockResponse.segment = serverResponse.segment;
        mockResponse.subSegment = serverResponse.subSegment;
        if (getSubsegmentOverride()) {
            mockResponse.subSegment = getSubsegmentOverride();
        }
    }
    return Promise.resolve(mockResponse);
};

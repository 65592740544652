import downshift, { StateChangeOptions } from 'downshift';
import { Organization } from '../../../lib/types/types';

export const handleStateChange = (changes: StateChangeOptions<any>, onChange: Function, openOrgSearchEnabled: boolean) => {
    const keyUp = changes.hasOwnProperty('inputValue') && (changes.type === downshift.stateChangeTypes.changeInput);
    const selectedOrgFromList = changes.hasOwnProperty('selectedItem');
    const blurredInput = changes.type === downshift.stateChangeTypes.mouseUp || changes.type === downshift.stateChangeTypes.blurInput;

    if (selectedOrgFromList) {
        const { selectedItem } = changes;
        onChange(selectedItem);
    } else if (keyUp) {
        const selectedItem: Organization = {
            id: 0,
            name: changes.inputValue,
        };
        onChange(selectedItem);
    } else if (blurredInput) {
        if (!openOrgSearchEnabled) {
            onChange({
                id: undefined,
                name: '',
            });
        }
    }
};

import React, { AllHTMLAttributes } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { setRef } from '../../../lib/refs/refs';

export type InputTextProps = {
    id: string; // used for the html `id` and `name` attributes
    isErrored: boolean;
    className?: string; // any additional classnames
    hidePlaceholder?: boolean; // placeholder should always be provided, even if hidden (default), so it is easy to reveal using css-only
    onChange?: React.ChangeEventHandler;
    onKeyDown?: React.ChangeEventHandler;
    ref?: never; // use refId instead. Setting the ref on this component, rather than the input inside here is not desired
    refId?: string; // e.g. if id is hyphen-case, and ref field name is camelCase, which is common
    required?: boolean;
    type?:'text' | 'email' | 'password' | 'search' | 'url' | 'tel';
}
& AllHTMLAttributes<HTMLInputElement>
& InjectedIntlProps;

const InputText = ({
    id,
    isErrored,
    className = undefined,
    hidePlaceholder = true,
    refId,
    required = false,
    type = 'text',
    intl,
    ...props // forward any remaining props to the input
}: InputTextProps) => {
    let internalClassName = '';
    if (className) internalClassName += `${className} `; // want this first
    internalClassName += 'sid-text-input sid-l-full-width';
    if (hidePlaceholder) internalClassName += ' sid-hidden-placeholder';
    if (required) internalClassName += ' sid-text-input--required';
    if (isErrored) internalClassName += ' sid-text-input--error';

    const finalRefId = refId ? refId : id;

    return (
        <input
            className={internalClassName}
            id={`sid-${id}`}
            name={`sid-${id}`}
            type={type}
            ref={(input => setRef(finalRefId, input))}
            {...props}
            aria-required={required}
        />
    );
};

export const InputTextComponent = injectIntl(InputText);

import { ProgramTheme, WithOrganization, VerificationResponse, ViewModel, InactiveMilitaryPersonalInfoViewModel, PersonalInfoResponse, VerificationStep, PersonalInfoViewModel, DocUploadViewModel, Locale, Country } from '../types/types';
import { getHook } from '../hooks/hooks';
import { VerificationStepsEnum } from '../types/runtimeTypes';
import { getEmptyViewModel } from '../ServerApi/VerificationRequestGetters';
import { MAX_DOC_UPLOAD_DOCS_ALLOWED, UPLOAD_FILE_PREFIX } from '../../constants';
import { deepClone } from '../utils/objects';

/**
 * @todo needs tests
 * @private
 */
export const handleSubmitResponse = (verificationResponse: VerificationResponse, viewModel: ViewModel): ViewModel => {
    const currentStep = verificationResponse.currentStep;

    if (currentStep === VerificationStepsEnum.success) {
        getHook('ON_VERIFICATION_SUCCESS')(verificationResponse);
    }

    // TODO: Find a better way to persist data between military steps.
    // Ensure the new viewModel for military steps persists data from the previous military step.
    let newViewModel: ViewModel;

    if (currentStep === VerificationStepsEnum.collectActiveMilitaryPersonalInfo) {
        newViewModel = { ...viewModel, dischargeDate: '' } as InactiveMilitaryPersonalInfoViewModel;
        delete newViewModel['dischargeDate'];
    } else if (currentStep === VerificationStepsEnum.collectInactiveMilitaryPersonalInfo) {
        if (!(viewModel as InactiveMilitaryPersonalInfoViewModel).dischargeDate) {
            newViewModel = { ...viewModel, dischargeDate: '' } as InactiveMilitaryPersonalInfoViewModel;
        } else {
            newViewModel = viewModel;
        }
    } else if (hasFailedInstantMatch(verificationResponse as PersonalInfoResponse)) {
        newViewModel = viewModel;
    } else { // If not military, and not on the confirm info 'step', get an empty viewModel
        const locale = verificationResponse.locale;
        newViewModel = initViewModel({ locale, previousViewModel: viewModel, currentStep: verificationResponse.currentStep });
    }
    return newViewModel;
};

/**
 * @private
 */
export const hasFailedInstantMatch = (verificationResponse: PersonalInfoResponse): boolean => {
    if (verificationResponse.instantMatchAttempts && verificationResponse.instantMatchAttempts > 0) {
        return true;
    }
    return false;
};

/**
 * @private
 */
export const initViewModel = ({
    previousViewModel,
    currentStep,
    locale,
    fingerprint,
    country,
}: {
    previousViewModel: ViewModel;
    currentStep: VerificationStep;
    locale: Locale;
    fingerprint?: string;
    country?: Country;
}): ViewModel => {
    let viewModel: ViewModel;

    viewModel = getEmptyViewModel(currentStep);

    // Attempt to bring over any previousViewModel values that apply to the new viewModel
    if (previousViewModel) {
        for (const key in viewModel) {
            if (previousViewModel.hasOwnProperty(key)) {
                viewModel[key] = previousViewModel[key];
            }
        }
    }
    if (viewModel) {
        if (fingerprint) {
            (viewModel as PersonalInfoViewModel).deviceFingerprintHash = fingerprint;
        }
        if (locale) {
            (viewModel as PersonalInfoViewModel).localeChoice = {
                value: locale || 'en-US',
                label: '',
            };
        }
        if (country && 'country' in viewModel && viewModel.country === undefined) {
            viewModel.country = country;
        }
        if (country && 'countryChoice' in viewModel && viewModel.countryChoice === undefined) {
            viewModel.countryChoice = {
                value: country,
                label: '',
            };
        }
    }

    return viewModel;
};

export const clearUploadedFiles = (viewModel: DocUploadViewModel) => {
    if (viewModel) {
        for (let i = 1; i <= MAX_DOC_UPLOAD_DOCS_ALLOWED; i += 1) {
            viewModel[`${UPLOAD_FILE_PREFIX}${i}`] = undefined;
        }
    }
};

export const collectFraudProfile = async (verificationId: string) => {
    import('../ThreatMetrix/index.js').then(({ ThreatMetrix }) => {
        if (ThreatMetrix !== undefined && ThreatMetrix.hasOwnProperty('profile') && typeof ThreatMetrix.profile === 'function') {
            const orgId = 'cnl2my47';
            const profilingDomain = 'content.sheerid.com';
            ThreatMetrix.profile(profilingDomain, orgId, verificationId);
        }
    }).catch(() => {
        // Do nothing - API call will handle this
    });
};

export const enhanceOrganizationFromTheme = (viewModel: ViewModel, programTheme: ProgramTheme) => {
    if (viewModel && viewModel.hasOwnProperty('organization') && programTheme.config.orgRemoteSource) {
        const cloned: ViewModel = deepClone(viewModel);
        const organization = (cloned as WithOrganization).organization;
        organization.source = programTheme.config.orgRemoteSource;
        return cloned;
    }
    return viewModel;
};

import Iframe from './iframe';
import Modal from './modal';
import { IframeUserConfiguration, PostMessagesOptions } from '../types/types';

export function loadInlineIframe(containerElement: HTMLElement, url: string, userConfig: IframeUserConfiguration = {}) {
    const iframe = new Iframe(containerElement, url, userConfig);
    iframe.init();
}

export function loadInModal(url: string, userConfig: IframeUserConfiguration = {}) {
    const modal = new Modal(url, userConfig);
    modal.init();
}

export function postVerificationSizeUpdates(options: PostMessagesOptions = { origin: '*', interval: 100 }) {
    if (self !== top) {
        let bodyHeight = document.body.offsetHeight;
        const interval = window.setInterval(() => {
            if (bodyHeight !== document.body.offsetHeight) {
                bodyHeight = document.body.offsetHeight;

                const heightWithComputedMargins = bodyHeight
                    + parseInt(window.getComputedStyle(document.body).marginTop, 10)
                    + parseInt(window.getComputedStyle(document.body).marginBottom, 10);
                const verificationUrl = new URL(window.location.href);
                const verificationIframeUid = verificationUrl.searchParams.get('verificationIframeUid');

                window.parent.postMessage({
                    verificationIframeUid,
                    action: 'updateHeight',
                    height: heightWithComputedMargins,
                }, options.origin);
            }
        }, options.interval);

        return () => clearInterval(interval);
    }
}

import React from 'react';
import { Organization } from '../../lib/types/types';

interface OrganizationListProps {
    getItemProps: any;
    getMenuProps: any;
    highlightedIndex: number;
    itemToString: any;
    organizations: Organization[];
    children: React.ReactNode;
    maxItemsToDisplay?: number;
}

const ROW_HEIGHT = 42;
const MAX_ITEMS = 25;

const OrganizationList = ({
    getItemProps,
    getMenuProps,
    highlightedIndex,
    organizations,
    itemToString,
    children,
    maxItemsToDisplay = MAX_ITEMS,
}: OrganizationListProps) => {
    const fullHeight = organizations.length * ROW_HEIGHT;

    const item = (organization: Organization, index: number) => {
        return (
            <div
                className={`sid-organization-list__item ${highlightedIndex === index ? 'sid-organization-list__item--highlighted' : ''}`}
                key={index}
                {...getItemProps({
                    index,
                    item: organization,
                })}
                >
                {itemToString(organization)}
            </div>
        );
    };
    return(
        <div className={'sid-organization-list__menu'}
            {...getMenuProps()}
            tabIndex={-1}
        >
            <div style={{ height: Math.min(fullHeight, 280) }}>
                { organizations.map((organization, index) => {
                    if (index <= maxItemsToDisplay - 1) {
                        return item(organization, index);
                    }
                })}
            </div>
            { children }
        </div>
    );
};

export const OrganizationListComponent = OrganizationList;

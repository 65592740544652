import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

interface CountDownProps {
    logo: React.ReactChild;
    countdownSeconds: number;
    onCountdownFinish: Function;
}

const CountDown = React.memo(({ countdownSeconds, onCountdownFinish, logo }: CountDownProps) => {
    const [timeRemaining, setTimeRemaining] = React.useState(countdownSeconds);
    const timeRemainingRef = React.useRef(timeRemaining);
    const timerRef = React.useRef(null);

    const tickTimer = () => {
        const newTime = timeRemainingRef.current - 1;
        setTimeRemaining(newTime);
        timeRemainingRef.current = newTime;
        if (newTime < 0) {
            onCountdownFinish();
            clearInterval(timerRef.current);
        }
    };

    const formatSeconds = () => {
        const seconds = (`0${timeRemaining}`).slice(-2);
        return `00:${seconds}`;
    };

    React.useEffect(() => {
        timerRef.current = setInterval(tickTimer, 1000);
        return () => clearInterval(timerRef.current);
    }, []);

    return (
        <div className="sid-header sid-l-horz-center" data-testid="sid-countdown-pending">
            <div className="sid-l-horz-center">
                { logo }
            </div>
            <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
                <FormattedHTMLMessage id="step.pending.titleCountdown" defaultMessage="Processing" />
            </div>
            <div className="sid-header__subtitle-one">
                <FormattedHTMLMessage
                    id="step.pending.subtitleCountdown"
                    defaultMessage="Hang tight while we upload and scan your documents."
                />
            </div>
            <div id="sid-step-pending" className="sid-l-container">
                <div className="sid-countdown">
                    {formatSeconds()}
                </div>
            </div>
            <div className="sid-l-space-top-xl" />
        </div>
    );
});
export const CountDownComponent = CountDown;

import React from 'react';
import ReactDom from 'react-dom';

import {
    logger,
    assertValidHtmlElement,
    VerificationForm,
    DatabaseId,
    ViewModel,
} from '../es6';

export class VerificationFormEs5 {
    element: HTMLElement;

    programId: DatabaseId;

    trackingId: string;

    viewModel: ViewModel;

    ref: React.RefObject<any>;

    constructor(element: HTMLElement, programId: DatabaseId, trackingId?: string) {
        // In case the 'new' keyword isn't used by es5 devs
        if (!(this instanceof VerificationFormEs5)) {
            throw new Error('Method "render" not found. Did you use the "new" keyword when instantiating VerificationForm?');
        }

        logger.info(`Constructing VerificationFormEs5 ${programId}`);
        assertValidHtmlElement(element);

        this.element = element;
        this.programId = programId;
        this.trackingId = trackingId;
        this.ref = React.createRef();

        this.render();
    }

    refresh() {
        this.ref.current.refresh();
    }

    reset() {
        this.ref.current.reset();
    }

    render() {
        ReactDom.render(<VerificationForm programId={this.programId} trackingId={this.trackingId} ref={this.ref} />, this.element);
    }
}

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

interface RequestOrganizationFormFooterComponentProps {
    faqUrl: string;
}

export const RequestOrganizationFormFooterComponent = ({ faqUrl }: RequestOrganizationFormFooterComponentProps) => (
    <div className="sid-footer">
        <div className="sid-footer__powered-by-container--stacked sid-l-horz-center sid-l-space-top-lg">
            <div className="sid-footer__powered-by--stacked sid-l-horz-center">
                <FormattedHTMLMessage
                    id="poweredBy"
                    defaultMessage="Verification services powered by SheerID."
                />
            </div>
            <div className="sid-l-space-top-sm">
                <a className="sid-faq sid-link" href={faqUrl} target="_blank">
                    <FormattedHTMLMessage
                        id="faq"
                        defaultMessage="SheerID Add School Request FAQ"
                    />
                </a>
            </div>
        </div>
    </div>
);

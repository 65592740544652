import React from 'react';
import ReactDom from 'react-dom';
import { IntlProvider } from 'react-intl';

import { DiscreteFieldDefault } from './DiscreteFieldDefault';
import { BirthDateComponent } from '../components/FormFields/BirthDate/BirthDateComponent';

export class BirthdateField extends DiscreteFieldDefault {
    constructor(element: HTMLElement) {
        super(element);
    }

    render() {
        ReactDom.render(
            (
                <IntlProvider locale={this.locale} key={this.locale} messages={this.messages}>
                    <BirthDateComponent
                        value={this.value}
                        onChange={(val: any) => this.doChange(val)}
                        isErrored={this.isErrored}
                        errorId={this.errorId}
                    />
                </IntlProvider>
            ),
            this.mountPoint,
        );
    }
}

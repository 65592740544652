/**
 * TODO - preamble
 */
import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { produce } from 'immer';

import { VerificationStepsEnum, FirstResponderStatusDefaultMessagesEnum } from '../../lib/types/runtimeTypes';
import { isFormFilled } from '../../lib/validators/validators';
import { hasFailedInstantMatch } from '../../lib/VerificationService/VerificationServiceHelpers';
import { setRef } from '../../lib/refs/refs';
import { setFocus } from '../../lib/utils/browser/inputHelpers';
import { getSafe } from '../../lib/utils/objects';
import {
    VerificationService,
    FieldValidationErrors,
    ExtendedFieldValidationErrors,
    FormSelectChoice,
    Organization,
    FirstResponderPersonalInfoViewModel,
    FirstResponderPersonalInfoResponse,
    InputSelectOnKeyDown,
} from '../../lib/types/types';
import {
    handleEmailOnKeyDown,
    submitForm,
    updateFieldValidationErrorsByFieldId,
    updateViewModelOrganization,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from '../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { PhoneNumberComponent as PhoneNumber } from '../FormFields/PhoneNumber/PhoneNumberComponent';
import { FirstNameComponent as FirstName } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent as LastName } from '../FormFields/LastName/LastNameComponent';
import { EmailComponent as Email } from '../FormFields/Email/EmailComponent';
import { BirthDateComponent as BirthDate } from '../FormFields/BirthDate/BirthDateComponent';
import { MarketConsentWrapperComponent as MarketConsentWrapper } from '../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent';
import { FirstResponderStatusComponent as FirstResponderStatus } from '../FormFields/FirstResponderStatus/FirstResponderStatusComponent';
import { FirstResponderOrganizationComponent as FirstResponderOrganization } from '../FormFields/FirstResponderOrganization/FirstResponderOrganizationComponent';
import { PostalCodeComponent } from '../FormFields/PostalCode/PostalCodeComponent';
import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { CountryComponentWrapper } from '../FormFields/Country/CountryComponentWrapper';
import { RewardsRemainingComponent } from '../RewardsRemaining/RewardsRemainingComponent';


interface StepFirstResponderPersonalInfoComponentProps {
    verificationService: VerificationService;
    intl: any;
}

export const StepFirstResponderPersonalInfo = ({ intl, verificationService }: StepFirstResponderPersonalInfoComponentProps) => {
    const viewModel = verificationService.viewModel as FirstResponderPersonalInfoViewModel;
    const fieldValidationErrors: FieldValidationErrors & ExtendedFieldValidationErrors = verificationService.fieldValidationErrors;
    const verificationResponse = verificationService.verificationResponse as FirstResponderPersonalInfoResponse;
    const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

    const updateFirstResponderViewModel = (key: keyof FirstResponderPersonalInfoViewModel, value: any) => {
        const nextState: FirstResponderPersonalInfoViewModel = produce(viewModel, (draft: FirstResponderPersonalInfoViewModel) => {
            (draft[key] as any) = value;
        });
        verificationService.updateViewModel(nextState);
    };

    const getAvailableFirstResponderStatuses = ():FormSelectChoice[] => {
        const availableStatusesResponse =
            (verificationService.verificationResponse as FirstResponderPersonalInfoResponse).availableStatuses;
        const availableStatuses: FormSelectChoice[] = [];

        for (const status of availableStatusesResponse) {
            availableStatuses.push({
                value: status,
                label: intl.formatMessage({ id: status, defaultMessage: FirstResponderStatusDefaultMessagesEnum[status] }),
            });
        }
        return availableStatuses;
    };

    const handleFirstResponderStatusOnKeyDown: InputSelectOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            setFocus('organization');
        }
    };

    const collectPostalCode = getSafe(() => verificationService.programTheme.openOrgSearchEnabled)
        && getSafe(() => viewModel.organization.id) === 0
        && getSafe(() => viewModel.organization.name, '').length > 0;

    if (collectPostalCode) {
        viewModel.postalCode = (viewModel.postalCode ? viewModel.postalCode : '');
    } else {
        if (viewModel.postalCode || viewModel.postalCode === '') {
            delete viewModel.postalCode;
            verificationService.fieldValidationErrors.postalCode = undefined;
        }
    }

    return (
        <div id="sid-step-active-first-responder-personal-info" className="sid-l-container">
            { failedInstantMatch ?
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.title" defaultMessage="Check Your Name, Email & Organization" />
                    </div>
                    <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
                        <FormattedHTMLMessage id="step.personalInfo.tryAgain.subtitle"
                            defaultMessage="Your full name, organization name, and email must match what is on record with your agency." />
                    </div>
                </div>
            :
                <div className="sid-header">
                    <div className="sid-l-horz-center">
                        <LogoComponent verificationService={verificationService} />
                    </div>
                    <div className="sid-header__title sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.title" defaultMessage="Unlock this 1st Responder-Only Offer" />
                    </div>
                    <div className="sid-header__subtitle sid-l-horz-center">
                        <FormattedHTMLMessage id="step.personalInfo.subtitle"
                            defaultMessage="Verify you're an active first responder." />
                    </div>
                    <div className="sid-header__how-verifying-works sid-l-horz-center">
                        <HowDoesVerifyingWork verificationService={verificationService} />
                        <RewardsRemainingComponent verificationService={verificationService} />
                    </div>
                </div>
            }

            <CountryComponentWrapper
                verificationService={verificationService}
                viewModel={viewModel}
                nextFocusField="status"
            />

            <FirstResponderStatus
                value={{
                    value: (verificationService.viewModel as FirstResponderPersonalInfoViewModel).status,
                    label: FirstResponderStatusDefaultMessagesEnum[(verificationService.viewModel as FirstResponderPersonalInfoViewModel).status],
                }}
                isErrored={!!fieldValidationErrors.status}
                options={ getAvailableFirstResponderStatuses() }
                onChange={
                    (status: FormSelectChoice) => {
                        updateFirstResponderViewModel('status', status ? (status.value as string) : '');
                        updateFieldValidationErrorsByFieldId('status', status ? (status.value as string) : '', verificationService);
                        if (status) {
                            setFocus('organization');
                        }
                    }
                }
                onKeyDown={event => handleFirstResponderStatusOnKeyDown(event)}
            />

            <FirstResponderOrganization
                isErrored={!!fieldValidationErrors.organization}
                onChange={
                    (choice: Organization) => {
                        updateViewModelOrganization(choice, verificationService);
                        updateFieldValidationErrorsByFieldId('organization', choice, verificationService);
                    }
                }
                value={(verificationService.viewModel as FirstResponderPersonalInfoViewModel).organization}
                verificationService={verificationService}
            />

            <div className="sid-names">
                <FirstName
                    value={viewModel.firstName}
                    isErrored={!!fieldValidationErrors.firstName}
                    onChange={
                        (newValue) => {
                            updateFirstResponderViewModel('firstName', newValue);
                            updateFieldValidationErrorsByFieldId('firstName', newValue, verificationService);
                        }
                    }
                />

                <LastName

                    value={viewModel.lastName}
                    isErrored={!!fieldValidationErrors.lastName}
                    onChange={
                        (newValue) => {
                            updateFirstResponderViewModel('lastName', newValue);
                            updateFieldValidationErrorsByFieldId('lastName', newValue, verificationService);
                        }
                    }
                />
            </div>

            <BirthDate
                isErrored={!!fieldValidationErrors.birthDate}
                errorId={fieldValidationErrors.birthDate}
                onChange={(newValue) => {
                    updateFirstResponderViewModel('birthDate', newValue);
                    updateFieldValidationErrorsByFieldId('birthDate', newValue, verificationService);
                }}
                value={viewModel.birthDate}
            />

            <Email
                value={viewModel.email}
                isErrored={!!fieldValidationErrors.email}
                explanation={
                    <FormattedHTMLMessage
                        id="emailExplanation"
                        defaultMessage="Needed to send you your unique code"
                    />
                }
                onChange={
                    (newValue) => {
                        updateFirstResponderViewModel('email', newValue);
                        updateFieldValidationErrorsByFieldId('email', newValue, verificationService);
                    }
                }
                onKeyDown={event => handleEmailOnKeyDown(event)}
            />
            {
                verificationService.programTheme.isSmsNotifierConfigured || verificationService.programTheme.smsLoopEnabled ?
                    <PhoneNumber
                        isRequired={!!verificationService.programTheme.smsLoopEnabled}
                        value={viewModel.phoneNumber}
                        isErrored={!!fieldValidationErrors.phoneNumber}
                        onChange={
                            (newValue) => {
                                updateFirstResponderViewModel('phoneNumber', newValue);
                                updateFieldValidationErrorsByFieldId('phoneNumber', newValue, verificationService);
                            }
                        }
                    />
                    : null
            }
            {
                collectPostalCode ?
                <PostalCodeComponent
                    isErrored={!!fieldValidationErrors.postalCode}
                    onChange={(newValue) => {
                        updateFirstResponderViewModel('postalCode', newValue);
                        updateFieldValidationErrorsByFieldId('postalCode', newValue, verificationService);
                    }}
                    value={viewModel.postalCode}
                />
                :
                null
            }

            <MarketConsentWrapper
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.marketConsentValue}
                onChange={(newValue) => {
                    updateFirstResponderViewModel('metadata', { ...verificationService.viewModel.metadata, marketConsentValue: newValue });
                    updateFieldValidationErrorsByFieldId('marketConsentValue', newValue, verificationService);
                }}
                viewModel={viewModel}
            />

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-btn-collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectFirstResponderPersonalInfo)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${!isFormFilled(viewModel, verificationService.formValidationOptions) ? 'sid-btn--disabled-like' : ''}`}
                        aria-labelledby="verify-status-text"
                        ref={button => setRef('submitButton', button)}
                    >
                        <span id="verify-status-text">
                            { failedInstantMatch ?
                                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
                            :
                                <FormattedHTMLMessage id="verifyMyFirstResponderStatus" defaultMessage="Verify My First Responder Status" />
                            }
                        </span>
                    </button>
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepFirstResponderPersonalInfoComponent = injectIntl(StepFirstResponderPersonalInfo);

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import {
    FormSelectChoice, FormFieldComponentProps, VerificationServiceProps, Organization,
} from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';
import { SortByLabel } from '../../../lib/utils/structures/FormSelectChoice';

interface BranchOfServiceComponentProps {
    organizations: Organization[];
}

export const BranchOfService = ({
    autoFocus,
    onChange,
    value,
    isErrored,
    organizations,
    intl,
    placeholder = '',
}: FormFieldComponentProps & VerificationServiceProps & BranchOfServiceComponentProps) => {
    const options: FormSelectChoice[] = [];
    if (organizations) {
        for (const org of organizations) {
            options.push({
                value: org.id,
                label: org.name,
            });
        }
    }

    const handleStateChange = (selectedOrgOption: FormSelectChoice, onChange: Function) => {
        if (selectedOrgOption) {
            onChange({
                id: selectedOrgOption.value,
                name: selectedOrgOption.label,
            });
        } else {
            onChange({
                id: 0,
                name: '',
            });
        }
    };

    return (
        <div className="sid-field sid-branch-of-service-id">
            <div className="sid-l-space-top-md" />
            <label htmlFor="sid-branch-of-service">
                <div className="sid-field__label sid-field__label--required sid-l-space-btm-sm">
                    <FormattedHTMLMessage
                        id="branchOfService"
                        defaultMessage="Branch Of Service"
                    />
                </div>
            </label>
            <InputSelectComponent
                autoFocus={autoFocus}
                fieldId="organization"
                inputId="sid-branch-of-service"
                isErrored={isErrored}
                options={SortByLabel(options)}
                onChange={(status: FormSelectChoice) => handleStateChange(status, onChange)}
                value={value}
                placeholder={placeholder || intl.formatMessage({ id: 'branchOfServicePlaceholder', defaultMessage: 'Branch Of Service*' })}
            />
            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidOrganization"
                            defaultMessage="You must select an organization from the list"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export const BranchOfServiceComponent = injectIntl(BranchOfService);

import { Locale } from '../../lib/types/types';
import { logger } from '../utils/logger/logger';
import { Locales } from '../intl/locales';

export { Locales } from '../intl/locales';

export enum VerificationStepsEnum {
    collectStudentPersonalInfo = 'collectStudentPersonalInfo',
    collectTeacherPersonalInfo = 'collectTeacherPersonalInfo',
    collectMemberPersonalInfo = 'collectMemberPersonalInfo',
    collectMilitaryStatus = 'collectMilitaryStatus',
    collectActiveMilitaryPersonalInfo = 'collectActiveMilitaryPersonalInfo',
    collectInactiveMilitaryPersonalInfo = 'collectInactiveMilitaryPersonalInfo',
    collectSeniorPersonalInfo = 'collectSeniorPersonalInfo',
    collectAgePersonalInfo = 'collectAgePersonalInfo',
    collectFirstResponderPersonalInfo = 'collectFirstResponderPersonalInfo',
    collectMedicalProfessionalPersonalInfo = 'collectMedicalProfessionalPersonalInfo',
    collectEmployeePersonalInfo = 'collectEmployeePersonalInfo',
    collectSocialSecurityNumber = 'collectSocialSecurityNumber',
    cancelSocialSecurityNumber = 'cancelSocialSecurityNumber',
    collectDriverLicensePersonalInfo = 'collectDriverLicensePersonalInfo',
    collectGeneralIdentityPersonalInfo = 'collectGeneralIdentityPersonalInfo',
    collectHybridIdentityPersonalInfo = 'collectHybridIdentityPersonalInfo',
    docUpload = 'docUpload',
    pending = 'pending',
    docReviewLimitExceeded = 'docReviewLimitExceeded',
    success = 'success',
    error = 'error',
    sso = 'sso',
    smsLoop = 'smsLoop',
    emailLoop = 'emailLoop',
}
export const VerificationSteps = Object.values(VerificationStepsEnum) as any; // For runtime checks

export enum TryAgainStepsEnum {
    docUpload = 'docUpload',
    pending = 'pending',
    error = 'error',
}
export const TryAgainSteps = Object.values(TryAgainStepsEnum) as any; // For runtime checks


export enum MockStepsEnum {
    loading = 'loading',
}

export const MockSteps = [
    ...Object.values(VerificationStepsEnum) as any,
    ...Object.values(MockStepsEnum) as any,
];


export enum SegmentEnum {
    STUDENT = 'student',
    MILITARY = 'military',
    TEACHER = 'teacher',
    MEMBER = 'member',
    SENIOR = 'senior',
    AGE = 'age',
    FIRST_RESPONDER = 'firstResponder',
    MEDICAL = 'medical',
    EMPLOYMENT = 'employment',
    IDENTITY = 'identity',
}
export const Segments = Object.values(SegmentEnum) as any; // For runtime checks


export enum SubSegmentEnum {
    ACTIVE_DUTY = 'activeDuty',
    VETERAN = 'veteran',
    RESERVIST = 'reservist',
    MILITARY_RETIREE = 'retiree',
    MILITARY_FAMILY = 'militaryFamily',
    FIREFIGHTER = 'fireFighter',
    POLICE = 'police',
    EMT = 'emt',
    NURSE = 'nurse',
    DRIVER_LICENSE = 'driverLicense',
    GENERAL_IDENTITY = 'generalIdentity',
    HYBRID_IDENTITY = 'hybridIdentity',
}
export const SubSegments = Object.values(SubSegmentEnum) as any; // For runtime checks

export enum MilitaryStatusDefaultMessagesEnum {
    ACTIVE_DUTY = 'Active Duty',
    MILITARY_RETIREE = 'Military Retiree',
    RESERVIST = 'Reservist',
    VETERAN = 'Veteran',
    MILITARY_FAMILY = 'Registered Military Dependent',
}

export enum FirstResponderStatusDefaultMessagesEnum {
    FIREFIGHTER = 'Firefighter',
    POLICE = 'Police',
    EMT = 'EMT',
}

export enum MedicalProfessionalStatusDefaultMessagesEnum {
    NURSE = 'Nurse',
    DOCTOR = 'Doctor',
    OTHER_HEALTH_WORKER = 'Other Health Worker',
}

export const MilitaryStatusDefaultMessages = Object.values(MilitaryStatusDefaultMessagesEnum) as any; // For runtime checks
export const FirstResponderStatusDefaultMessages = Object.values(FirstResponderStatusDefaultMessagesEnum) as any; // For runtime checks
export const MedicalStatusdefaultMessages = Object.values(MedicalProfessionalStatusDefaultMessagesEnum) as any; // For runtime checks


export enum FieldIdEnum {
    firstName = 'firstName',
    lastName = 'lastName',
    memberId = 'memberId',
    organization = 'organization',
    birthDate = 'birthDate',
    email = 'email',
    phoneNumber = 'phoneNumber',
    postalCode = 'postalCode',
    address1 = 'address1',
    city = 'city',
    country = 'country',
    state = 'state',
    dischargeDate = 'dischargeDate',
    docUpload = 'docUpload',
    status = 'status',
    marketConsentValue = 'marketConsentValue',
    socialSecurityNumber = 'socialSecurityNumber',
    carrierConsentValue = 'carrierConsentValue',
    driverLicenseNumber = 'driverLicenseNumber',
}
export const FieldIds = Object.values(FieldIdEnum) as any; // For runtime checks

export enum HookNameEnum {
    ON_VERIFICATION_READY = 'ON_VERIFICATION_READY',
    ON_VERIFICATION_SUCCESS = 'ON_VERIFICATION_SUCCESS',
    ON_VERIFICATION_STEP_CHANGE = 'ON_VERIFICATION_STEP_CHANGE',
}
export const HookNames = Object.values(HookNameEnum) as any;

/**
 * Add to the list of known locales for more helpful runtime checks.
 */
export const registerAdditionalLocales = (locales: Locale[]): void => {
    logger.info(`Registering additional locales ${locales.join(', ')}`);
    Locales.push(...locales);
};

export enum StateEnum {
    AK = 'AK',
    AL = 'AL',
    AR = 'AR',
    AZ = 'AZ',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DC = 'DC',
    DE = 'DE',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    IA = 'IA',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    MA = 'MA',
    MD = 'MD',
    ME = 'ME',
    MI = 'MI',
    MN = 'MN',
    MO = 'MO',
    MS = 'MS',
    MT = 'MT',
    NC = 'NC',
    ND = 'ND',
    NE = 'NE',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NV = 'NV',
    NY = 'NY',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VA = 'VA',
    VT = 'VT',
    WA = 'WA',
    WI = 'WI',
    WV = 'WV',
    WY = 'WY',
}

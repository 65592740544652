import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { VerificationService } from '../../lib/types/types';
import { FaqLinkComponent } from '../FaqLink/FaqLinkComponent';

interface Props {
    verificationService: VerificationService;
    isCentered?: boolean;
}

const PoweredByComponent: React.SFC<Props> = ({ verificationService, isCentered }) => (
    <div className={isCentered ? 'sid-footer__powered-by-container--stacked sid-l-horz-center' : 'sid-footer__powered-by-container'}>
        <div className={isCentered ? 'sid-footer__powered-by--stacked sid-l-horz-center' : 'sid-footer__powered-by'}>
            <FormattedHTMLMessage
                id="poweredBy"
                defaultMessage="Verification services powered by SheerID."
            />
        </div>
        <div className={isCentered ? 'sid-l-space-top-sm' : ''}>
            &nbsp;
            <FaqLinkComponent verificationService={verificationService} />
        </div>
    </div>
);

export { PoweredByComponent };

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormSelectChoice, InputSelectComponentProps } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';

export const MedicalStatus = ({
    value,
    isErrored,
    options,
    onChange,
    intl,
    placeholder = '',
    onKeyDown = undefined,
}: InputSelectComponentProps) => {
    const id = 'sid-medical-status';

    return (
        <div className="sid-field sid-medical-status">
            <div className="sid-l-space-top-md" />
            <label htmlFor={id}>
                <div id={`${id}-label`} className="sid-field__label sid-field__label--required sid-medical-status__label sid-l-space-btm-sm">
                    <FormattedHTMLMessage
                        id="status"
                        defaultMessage="Status"
                    />
                </div>
            </label>
            <InputSelectComponent
                fieldId="status"
                inputId={id}
                isErrored={isErrored}
                options={options}
                onChange={(status: FormSelectChoice) => onChange(status)}
                onKeyDown={onKeyDown}
                value={value}
                placeholder={placeholder || intl.formatMessage({ id: 'statusPlaceholder', defaultMessage: 'Status*' })}
                isRequired
            />
            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidStatus"
                            defaultMessage="Invalid medical status"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export const MedicalStatusComponent = injectIntl(MedicalStatus);

import React from 'react';
import Downshift from 'downshift';

import { FetchOrganizationsComponent } from '../../FetchOrganizationsComponent';
import { Organization, DatabaseId, Country, Locale } from '../../../lib/types/types';
import { setRef } from '../../../lib/refs/refs';
import { assertValidFunction } from '../../../lib/types/assertions';
import { getSafe } from '../../../lib/utils/objects';
import { handleStateChange } from './TypeaheadComponentHelper';

interface TypeaheadProps {
    onChange: any;
    programId: DatabaseId;
    inputHtmlId: string;
    autoFocus?: boolean;
    className?: string;
    countryCode?: Country;
    disabled?: boolean;
    minimumSearchValueLength?: number;
    placeholder?: string;
    value?: Organization;
    openOrgSearchEnabled?: boolean;
    orgSearchUrl?: string;
    orgSearchTags?: string;
    isRequired?: boolean;
    locale?: Locale;
    urlAddSchoolForm?: string;
}

const Typeahead = ({
    autoFocus,
    onChange,
    programId,
    className,
    countryCode,
    disabled,
    minimumSearchValueLength,
    placeholder,
    value,
    inputHtmlId,
    openOrgSearchEnabled,
    orgSearchUrl,
    orgSearchTags,
    isRequired,
    locale,
    urlAddSchoolForm,
}: TypeaheadProps) => {
    const itemToString = (item: Organization) => (item ? item.name : '');

    const handleOnLoaded = (loadedOrganizations, clearItems, setHighlightedIndex, setItemCount) => {
        clearItems();
        if (loadedOrganizations) {
            setHighlightedIndex(loadedOrganizations.length ? 0 : null);
            setItemCount(loadedOrganizations.length);
        }
    };

    assertValidFunction(onChange);

    return (
        <Downshift
            id={inputHtmlId}
            onStateChange={changes => handleStateChange(changes, onChange, openOrgSearchEnabled)}
            itemToString={itemToString}
            initialSelectedItem={value && value.id && value.name ? value : undefined}
        >
            {({
                clearItems,
                getInputProps,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
                setHighlightedIndex,
                setItemCount,
            }) => (
                <div className="sid-organization-list">
                    <input
                        {...getInputProps({ onFocus: openMenu, value: getSafe(() => value.name) })}
                        autoFocus={autoFocus}
                        className={`sid-l-full-width sid-hidden-placeholder sid-text-input ${className}`}
                        disabled={disabled}
                        id={inputHtmlId}
                        placeholder={placeholder}
                        ref={input => setRef('organization', input)}
                        aria-required={isRequired}
                    />
                    {isOpen
                        ? (
                            <FetchOrganizationsComponent
                                countryCode={countryCode}
                                getItemProps={getItemProps}
                                getMenuProps={getMenuProps}
                                highlightedIndex={highlightedIndex}
                                itemToString={itemToString}
                                minimumSearchValueLength={minimumSearchValueLength}
                                onLoaded={loadedOrganizations => handleOnLoaded(loadedOrganizations, clearItems, setHighlightedIndex, setItemCount)}
                                programId={programId}
                                searchValue={inputValue}
                                orgSearchUrl={orgSearchUrl}
                                orgSearchTags={orgSearchTags}
                                locale={locale}
                                urlAddSchoolForm={urlAddSchoolForm}
                                openOrgSearchEnabled={openOrgSearchEnabled}
                            />
                        )
                        : null}
                </div>
            )}
        </Downshift>
    );
};

export const TypeaheadComponent = Typeahead;

import { createStore } from 'redux';
import { verificationServiceReducer } from '../../lib/VerificationService/verificationServiceReducer';
import { ReduxState, VerificationServiceAction, VerificationStore } from '../types/types';
import { logger } from '../utils/logger/logger';

let store: VerificationStore;

export const getStore = (): VerificationStore => {
    if (!store) {
        store = createStore<ReduxState, VerificationServiceAction, void, void>(
            verificationServiceReducer,
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        );
        logger.init(store);
    }

    return store;
};

export const resetStore = () => store.dispatch({ type: 'RESET_STATE' });
export const refreshStore = () => store.dispatch({ type: 'FORCE_UPDATE' });

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';

interface PassedProps {
    onKeyDown?: Function;
}

export const Address = ({
    value,
    isErrored,
    onChange,
    intl,
    onKeyDown = undefined,
    placeholder = '',
}: FormFieldComponentProps & PassedProps) => (
    <div className="sid-field sid-address">
        <div className="sid-l-space-top-md" />
        <label htmlFor="sid-address">
            <div className={`sid-field__label sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                <div className="sid-field__label sid-field__label--required">
                    <FormattedHTMLMessage
                        id="address"
                        defaultMessage="Address"
                    />
                </div>
            </div>
        </label>

        <InputTextComponent
            id="address"
            isErrored={isErrored}
            onChange={e => onChange(e.target.value)}
            onKeyDown={typeof onKeyDown === 'function' ? e => onKeyDown(e) : undefined}
            placeholder={placeholder || intl.formatMessage({ id: 'addressPlaceholder', defaultMessage: 'Address*' })}
            value={value}
        />

        {
            isErrored ? (
                <div className="sid-field-error">
                    <FormattedHTMLMessage
                        id="errorId.invalidAddress"
                        defaultMessage="Invalid address"
                    />
                </div>
            ) : null
        }
    </div>
);

export const AddressComponent = injectIntl(Address);

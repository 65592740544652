import React from 'react';
import { PoweredByComponent } from '../PoweredBy/PoweredByComponent';
import { VerificationService, Locale, ViewModel } from '../../lib/types/types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { getSafe } from '../../lib/utils/objects';
import { PrivacyPolicyLinkComponent } from '../PrivacyPolicyLink/PrivacyPolicyLinkComponent';
import { getLocaleSafely } from '../../lib/intl/intl';
import produce from 'immer';

interface FormFooterProps {
    verificationService: VerificationService;
    children?: any;
}

const FormFooterComponent = ({ verificationService, children }: FormFooterProps) => {
    const { viewModel, updateViewModel } = verificationService;
    const countryChoice = getSafe<string>(() => viewModel.countryChoice.value, undefined);
    const locale: Locale = getLocaleSafely(viewModel);
    const privacyPolicyRef = React.useRef<any>({});
    const cleanupCommonTags = /(<div[^>]+>|<div>|<\/div>)|(<span[^>]+>|<span>|<\/span>)|(<p[^>]+>|<p>|<\/p>)/gm;

    // Store the footer message that the user was shown when the user submitted
    React.useEffect(() => {
        if (!privacyPolicyRef.current) {
            return;
        }
        const privacyPolicyText = privacyPolicyRef.current.innerHTML.replace(cleanupCommonTags, '');
        const updatedViewModel = produce(viewModel, (draft: ViewModel) => {
            if (!draft.metadata) {
                draft.metadata = {};
            }
            draft.metadata.submissionOptIn = privacyPolicyText;
        });

        updateViewModel(updatedViewModel);
    }, [privacyPolicyRef, locale]);

    return (
        <div className="sid-footer sid-h-small-text sid-l-space-top-md">
            <div className="sid-footer__shield">
                <img
                    title="Powered by SheerID"
                    alt="design element"
                    src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/shield-check.svg"
                />
            </div>
            <div>
                <div className="sid-footer__one" ref={privacyPolicyRef}>
                    {children ? children : (
                        <div>
                            <FormattedHTMLMessage
                                id="infoShared1"
                                defaultMessage=
                                    "By clicking, I consent to processing my information for the purpose of verifying my eligibility as described within the {privacyPolicyLink}"
                                values= {{ privacyPolicyLink: '' }}
                            />
                            <PrivacyPolicyLinkComponent verificationService={verificationService} includeCompanyName={true} />
                            <FormattedMessage id="companyName" defaultMessage="{Company}">
                                {companyName => (
                                    <FormattedHTMLMessage
                                        id="infoShared2"
                                        defaultMessage=". I understand that my information will be shared with {companyName}. "
                                        values={{ companyName }}
                                    />
                                )}
                            </FormattedMessage>
                            {
                                typeof countryChoice === 'string' && countryChoice.length > 0 && countryChoice !== 'US' ?
                                    <FormattedHTMLMessage
                                        id="informationTransferredToUS"
                                        defaultMessage=
                                            "Your information will be transferred to the United States and will be treated according to SheerID’s privacy policy."
                                    />
                                : null
                            }
                        </div>
                    )}
                </div>
                <div className="sid-footer__two">
                    <PoweredByComponent verificationService={verificationService} />
                </div>
            </div>
        </div>
    );
};

export { FormFooterComponent };

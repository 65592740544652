import React from 'react';

import { VerificationService } from '../../lib/types/types';
import { getLogoUrl } from '../../lib/ProgramTheme/programThemeGetters';
import { FormattedMessage } from 'react-intl';

interface LogoProps {
    verificationService: VerificationService;
}

const LogoComponent = ({ verificationService }: LogoProps) => {
    const logoUrl = getLogoUrl(verificationService.programTheme);

    if (logoUrl) {
        return (
            <div className="sid-logo sid-l-space-top-md">
                <FormattedMessage id="companyName" defaultMessage="{Company}">
                    {companyName => (
                        <img
                            className="sid-logo__img"
                            alt={`${companyName} logo`}
                            src={logoUrl}
                        />
                    )}
                </FormattedMessage>
            </div>
        );
    }
    return null;
};

export { LogoComponent };

import React from 'react';
import { IntlProvider } from 'react-intl';
import { CustomTextWrapper } from '../CustomTextWrapper';
import { RequestOrganizationLoadStepComponent } from './RequestOrganizationLoadStepComponent';

export const NewInnerRequestOrganizationFormComponent = ({
    programId, locale, messages, requestOrganizationService,
}) => (
    <IntlProvider locale="en-US" key={locale} messages={messages} textComponent={CustomTextWrapper}>
        <RequestOrganizationLoadStepComponent requestOrganizationService={requestOrganizationService} />
    </IntlProvider>
);

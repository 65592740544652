import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';

export const LastName = ({
    value,
    isErrored,
    onChange,
    intl,
    placeholder = '',
}: FormFieldComponentProps) => (
    <div className="sid-field sid-last-name">
        <div className="sid-l-space-top-md" />
        <label htmlFor="sid-last-name">
            <div className={`sid-field__label sid-field__label--required sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                <FormattedHTMLMessage
                    id="lastName"
                    defaultMessage="Last name"
                />
            </div>
        </label>

        <InputTextComponent
            id="last-name"
            isErrored={isErrored}
            onChange={event => onChange(event.target.value)}
            placeholder={placeholder || intl.formatMessage({ id: 'lastNamePlaceholder', defaultMessage: 'Last Name*' })}
            refId="lastName"
            value={value}
            required
        />

        {
            isErrored ? (
                <div className="sid-field-error">
                    <FormattedHTMLMessage
                        id="errorId.invalidLastName"
                        defaultMessage="Invalid last name"
                    />
                </div>
            ) : null
        }
    </div>
);

export const LastNameComponent = injectIntl(LastName);

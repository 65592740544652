import { assertValidFunction } from '../types/assertions';

// Create IE + others compatible event handler
const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
const eventer = window[eventMethod];
const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

/**
 * @private
 */
export const speakToOuterFrame = (message: object) => {
    if (window.parent && window.parent.postMessage && typeof window.parent.postMessage === 'function') {
        window.parent.postMessage(message, '*');
    }
};

/**
 * @private
 */
export const listenToSheerIdFrame = (callback: Function) => {
    assertValidFunction(callback);
    const receiveMessage = (event) => {
        callback(event);
    };
    eventer(messageEvent, receiveMessage, false);
};
